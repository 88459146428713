import React from "react";
import cmsUtils from "cms/utils/cmsUtils";
import { Item } from "cms/items/item";
import utils from "utils";

export function Panel(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  //console.log('item', item, subItems);

  const bgColor = cmsUtils.payload(item, "BgColor");
  //const textColorTheme = cmsUtils.payload(item, 'TextColorTheme');
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");
  const bgTheme = cmsUtils.payload(item, "TextColorTheme") || "white";
  //console.log(bgTheme)

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );

  const cssClass = utils.classNames(
    "cms_item",
    "contentPanel",
    "contentPanel--" + bgTheme,
    item.cssClass || item.anchorName || ""
  );

  const subItemsElts = subItems.map((subItem, index) => (
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  ));

  return (
    <section
      className={cssClass}
      style={{
        backgroundColor: `${bgColor}`,
        backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
      }}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      {/* <div className="contentPanel__bg"></div> */}
      <div className='container'>{subItemsElts}</div>
    </section>
  );
}
