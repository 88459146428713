//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    multiColumnPanelMain : css({
        maxWidth : '960px',
        margin : 'auto',
        '& .container' : mq({
            marginLeft: ['auto',null,null,null,'-15px'],
            marginRight: ['auto',null,null,null,'-15px']
        })
    })
}