import React, { useState, useEffect } from 'react';
import { useRouter } from 'components';
import { useGet } from 'components';
import { Item } from 'cms/items/item';
import { NotFound } from 'components';
import env from 'env';
import { useStore } from "store/storeProvider";
import { ErrorPopup, Loading } from 'components';
import { useInstagram } from 'components';
import { useAuth } from '../../../auth/index';
import cmsUtils from '../../utils/cmsUtils';
import { Redirect } from "react-router-dom";
import { gtm } from '../../../lib/tracking';

const log = (...args) => {
  //console.log(args);
}
const areEqual = (prevProps, nextProps) => {
  return (prevProps.pageData === nextProps.pageData)
};
const PageMemo = React.memo(Page, areEqual)

export function ViewPage() {
  const { dispatch } = useStore();
  const [pageLoaded, setPageLoaded] = useState(false);
  //const [page, setPage] = useState(null);
  //const path = usePath();
  //log('view page', path);
  
  //const { pathname } = useRouter();

  const router = useRouter();
  //const { pathname } = router;
  const { pathname, Redirect } = router;

  useInstagram();

  const get = useGet();
  log('0. viewpage ready');

  useEffect(() => {
    log('*. start loading')
    setPageLoaded(false);
    get.send(env.apiBase + "/api/cms/GetPublishedPage?path=" + pathname);
    // eslint-disable-next-line
  }, [pathname]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  //log(router.pathname);
  //log(get.isLoading());
  log('1. viewpage component', pageLoaded, get.response);
  
  const pageData = get.response.data;
  const menuData = get.response.menu;
  const redirectTo = get.response.redirectTo;
  const cmsOption = {};
  
  log(pageLoaded, !pageLoaded && !!pageData)
  if (!pageLoaded && pageData) {
    //log(pageLoaded, pageData, menuData)
    log('2. dispatching page & menu')
    setPageLoaded(true);
    //dispatch({ type: 'SET_STATE', payload: { page: pageData, menu: menuData } })
    dispatch({ type: 'SET_PAGE', payload: pageData });
    dispatch({ type: 'SET_MENU', payload: menuData });
    dispatch({type:'SET_STATE', payload : {preParkName : "" }});
    document.title = pageData.pageTitle;
    gtm.pageView(pathname, pageData.pageTitle);
    return null;
  } else if (redirectTo) {
    return <Redirect to={redirectTo} />
  } else if (!pageData) {
    return (<NotFound />)
  }
  
  log('3. call rendering memo')
  return <PageMemo pageData={pageData} cmsOption={cmsOption} />;
}

function Page(props) {
  const pageData = props.pageData;
  const cmsOption = props.cmsOption;
  const _auth = useAuth();
  const [pageLoading, setPageLoading] = useState(true);
  const [checkAuthSession, setCheckAuthSession] = useState(0);
  // document.title = pageData.pageTitle;
  log('4. page rendering', pageData, cmsOption)

  const sectionItems = pageData.sections.map((section, index) => {
      return (
          <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
      )
    }
  );

  const pageType = cmsUtils.payload(pageData, "PageType") || "public";
  useEffect(()=> {
    if(pageType === "public"){
      setPageLoading(false);
      return;
    }
    else if(pageType === "member"){
      if(_auth.session && !_auth.session.expired()){
        setPageLoading(false);
      }
      else{
        if(checkAuthSession >= 2 ){
          setPageLoading(false);
          return;
        }
        setTimeout(() => {
          setCheckAuthSession(checkAuthSession + 1); 
        }, 1500);
      }
    }
  }, [_auth.session, checkAuthSession, pageType]);

  return (
    pageLoading ? <Loading /> : (checkAuthSession === 2 ? <Redirect to="/members-login"></Redirect> :
      <div className={`cmsPage cmsPage--${pageData.pageTypeCode}`}>
        {sectionItems}
      </div>
    )
  );
}

function Section(props) {
  log('5. section rendering')
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  if (!subItems || subItems.length === 0)
    return (<></>);
  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}