/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useContext } from 'react';
//import { SiteLink } from 'components';
//import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import style from './style';
import { Link } from 'react-router-dom';
import { AtdwContext } from '../index';

export function AtdwTile(props){

    const product = props;
    const fromCalled = props.fromCalled || "";
    const _atdwContext = useContext(AtdwContext);
    const checkPetFriendly = product.checkPetFriendly ||  false;

    //console.log("product ---", product);
    
    const changeUpperCases = ((str) => {
        return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
    });

    return(
        <div css={style.atdwTile} className="atdwTile" id={product.productId}>
            <Link to={product.slug.includes('/atdw') ? product.slug : '/atdwproduct/' + product.slug + ((_atdwContext.atdwTxaCheckInDate && _atdwContext.atdwTxaCheckInDate !== "") ? '?txacheckindate=' + _atdwContext.atdwTxaCheckInDate : '')} onClick={()=>{
                if(fromCalled !== "search"){
                    //_atdwContext.setupAtdwDataHistory2(product.productId);
                }
                else{
                    //console.log("ASFDsdfsdfsfdkj");
                }
            }}>
                <div css={style.bg} className="bg" style={{ backgroundImage: utils.css.bgUrlStyle(product.imageUrl) }}>
                    {
                        (checkPetFriendly && checkPetFriendly === true) &&
                        <Fragment>
                            <div css={style.petIcon}>
                                <img src='/assets/pet-friendly-v2.png' alt="pet"  width="100%" />
                            </div>
                            <div css={style.petIconBg}></div>
                        </Fragment>
                    }
                </div>
                <div css={style.town} className="town">
                    {product.town === product.town.toUpperCase() ? changeUpperCases(product.town) : product.town }
                </div>
                <div css={style.descContainer}  className="desc-container">
                    <div css={style.title} className="title">
                        { product.productName === product.productName.toUpperCase() ? changeUpperCases(product.productName) : product.productName }
                    </div>
                    <hr />
                    {/* <div css={style.desc} className="desc">
                        {
                            product.additionalData.PRODUCTTYPES.map((item, index)=> {
                                if(product.additionalData.PRODUCTTYPES.length === (index + 1)){
                                    return(
                                        <span key={index}>{item.productTypeDescription}</span>
                                    )
                                }
                                else{
                                    return (
                                        <span key={index}>{item.productTypeDescription} | </span>
                                    )
                                }
                            })
                        }
                    </div> */}
                    <div css={style.shotDesc} className="shot-desc">
                        {product.shortDesc}
                    </div>
                </div>
            </Link>
        </div>
    )
}