/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from 'utils';
import { AtdwSearchResult } from '../searchResult/view';
import { useState, useEffect, useMemo } from 'react';
import env from 'env';
import cmsUtils from '../../../cms/utils/cmsUtils';

function getPages(pagesSerialized) {
    if (pagesSerialized) {
      return JSON.parse(pagesSerialized);
    }
    return [];
}

export function MixedListings(props){

    const item = props.item;
    //const pages = getPages(cmsUtils.payload(item, 'PageObjects'));
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'mixedlistings', item.cssClass || item.anchorName || '');
    const [atdwData, setAtdwData] = useState();

    const pages = useMemo(() => {
        return getPages(cmsUtils.payload(item, 'PageObjects'));
    },[item]);

    useEffect(() => {
        console.log('useEffect-MixedListings');
        const fetchData = async () =>{
            const res = await fetch(env.apiBase + "/api/atdw/getmixedlistings");
            const data = await res.json();
            //console.log("data", data);
            for(var index in pages){
                let product = {
                    productId : 'pagetypetile' + index,
                    slug : pages[index].pageUrl,
                    town : pages[index].tileButtonText,
                    productName : pages[index].pageTitle,
                    additionalData : {PRODUCTTYPES : []},
                    shortDesc : pages[index].tileDesc,
                    imageUrl : pages[index].imageUrl
                };
                data.products.push(product);
            } 
            setAtdwData(data.products);
        }
        fetchData();
    }, [pages]);

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                atdwData && <AtdwSearchResult atdwData = {atdwData} />
            }
        </div>
    )
}