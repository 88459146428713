/** @jsx jsx */
import { jsx } from '@emotion/core'
//import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
import style from './style';
import { useAuth } from '../../auth/index'
//import { useRouter } from 'components';
import { useState, useEffect } from 'react';
import env from 'env';
import { Loading } from 'components';
import { Redirect } from 'react-router-dom';

export function ChangeDetails(props){
    
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'login', item.cssClass || item.anchorName || '');
    
    const _auth = useAuth();
    const [info, setInfo] = useState();
    const [buttonTrigger, setButtonTrigger] = useState(0);
    const [pageLoading, setPageLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    const [msg, setMsg] = useState();
    
    const handChange = (el => {
        const elName = el.target.name;
        const elValue =el.target.value;
        setInfo({...info, [elName] : elValue});
    });

    function onSubmit(e) {
        e.preventDefault();   
        e.stopPropagation();
        setPageLoading(true);
        setMsg(null);
        setButtonTrigger(buttonTrigger + 1);
    }

    useEffect(() => {    
        if(_auth.session && !_auth.session.expired() && !cmsOption.isCmsEdit){
            const fetchData = async () => {
                const res = await fetch(env.apiBase + '/api/profile/getuserprofile/' + _auth.session.uid, {
                    method : 'get',
                    headers: { 'Authorization': `Bearer ${_auth.session.token}` }
                });
                const data = await res.json();
                if(data.errors.length === 0){
                    setInfo({
                        organisation : data.results.userInfo.organisation || '',
                        name : data.results.userInfo.name || '',
                        password : '',
                        checkPassword : false,
                        passwordClue : data.results.userInfo.passwordClue || '',
                        email : data.results.userInfo.email || '',
                        token : _auth.session.token
                    });
                    setPageLoading(false);
                }
            }
            fetchData();
        }
        else{
            return;
        }
    }, [ _auth.session, cmsOption.isCmsEdit ]);

    useEffect(() => {
        if(buttonTrigger === 0){
            return;
        }
        const fetchData = async () => {
            const res = await fetch(env.apiBase + '/api/profile/updateinfo', {
                method : 'post',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${_auth.session.token}`
                },
                body: JSON.stringify(info)
            });
            const data = await res.json();
            console.log(data);
            if(data.errors.length === 0){
                setInfo({
                    organisation : data.results.userInfo.organisation || '',
                    name : data.results.userInfo.name || '',
                    password : '',
                    checkPassword : false,
                    passwordClue : data.results.userInfo.passwordClue || '',
                    email : data.results.userInfo.email || '',
                    token : _auth.session.token
                });
                setTimeout(() => {
                    setPageLoading(false);
                    if(data.results.resetPassword === true){
                        _auth.signout().then(user => {
                            setRedirect(true);
                        }); 
                    }
                }, 2000);
            }
            else{
                setMsg(data.errors[0].errorMessage);
                setPageLoading(false);
            }
        }
        fetchData();
        setButtonTrigger(0);
    }, [buttonTrigger, info, _auth]);

    return(

        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                cmsOption.isCmsEdit ?
                <pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
                    <b> Change Details </b>
                </pre> : (
                    pageLoading ? <Loading></Loading> : (redirect ? <Redirect to="/members-login"></Redirect> :
                    <form css={style.formContainer} autoComplete="off" onSubmit = {onSubmit}>
                
                    <div className="form-group">
                        <label htmlFor="username"> Organisation * </label>
                        <input className="form-control" name="organisation" onChange={handChange} value={info.organisation} required />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="username"> Name * </label>
                        <input className="form-control" name="name" onChange={handChange} value={info.name} required />
                    </div>
    
                    <div className="form-group form-check">
                        <input type="checkbox" className="form-check-input" name="checkPassword" id="checkPassword" onChange={(e)=>{
                            handChange({target : {name : "checkPassword", value : e.target.checked}});
                        }} checked={info.checkPassword} />
                        <label className="form-check-label" htmlFor="checkPassword"> I am NOT a spambot! </label>
                    </div>
                    
                    {
                        info.checkPassword ?
                        <div className="form-group">
                            <label htmlFor="password"> Password * </label>
                            <input type="password" className="form-control" name="password" onChange={handChange} value={info.password} required />
                        </div> : null
                    }
    
                    <div className="form-group">
                        <label htmlFor="password"> Password Clue </label>
                        <input className="form-control" name="passwordClue" onChange={handChange} value={info.passwordClue} />
                    </div>
                    
                    <div className="form-group">
                        <label htmlFor="username"> Email </label>
                        <input className="form-control" name="email" onChange={handChange} value={info.email} />
                    </div>
                    {
                        msg ?
                        <div>
                            {msg}
                        </div> : null
                    }
                    
                    <div className="form-group" style={{textAlign:'center'}}>
                        <button type="submit" className="btn btn-primary">
                            SAVE
                        </button>
                    </div>
                </form>
                    
                    )
                    
                )
            }
        </div> 
    )
}