/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import env from "../../../../env";
import { usePost, Button, ErrorMessage } from "../../../../components";
import { useForm } from "../../../../form";
import { getSignupFormModel } from "./signupFormModel";
import cmsUtils from "cms/utils/cmsUtils";
import utils from "utils";
import { useState, useEffect } from "react";
import { createPortal } from "react-dom";

function Portal({ children }) {
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}

export function SignupForm(props) {
  const item = props.item;
  // const emailReceiver = cmsUtils.payload(item, "EmailReceiver");
  const emailReceiver = props.emailReceiver;

  const boxTitle = cmsUtils.payload(item, "Title");
  const boxSubTitle = cmsUtils.payload(item, "SubTitle");
  const backgroundImg = cmsUtils.payload(item, "ImageUrl");
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (JSON.parse(localStorage.getItem("donot")) === true) {
        setModalOpen(false);
      } else {
        setModalOpen(true);
      }
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  const doNotShow = () => {
    localStorage.setItem('donot', JSON.stringify(true));
    setModalOpen(false);
  }

  const model = getSignupFormModel({});
  const form = useForm(model, { usePlaceholder: true });
  const [sendingStatus, setSendingStatus] = React.useState(null);
  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), emailReceiver: emailReceiver };
      // var files = form.getFiles();
      // var formData = new FormData();
      // formData.append("jsonData", JSON.stringify(value));
      // files.forEach((file) => {
      //   (file.files || []).forEach((uploadFile) => {
      //     formData.append(file.name, uploadFile);
      //   });
      // });
      // post.sendFormData(env.apiBase + "/api/signup/send", formData);
      post.send(env.apiBase + "/api/signup/send", value);
      setSendingStatus("pending");
    });

    // console.log("submitting");
  }

  if (post.done() && sendingStatus === "pending") {
    form.reset(model);
    setSendingStatus(null);
  }

  const render = (name) => form.renderControl(name, null);

  return (
    <div>
      {modalOpen && (
        <Portal>
          {modalOpen && (
            <div className='signUpFormContainer'>
              <form
                className='signUpForm'
                style={{
                  backgroundImage: utils.css.bgUrlStyle(backgroundImg),
                  backgroundSize: "cover",
                }}
              >
                <div className='signUpFormInside'>
                  <div
                    className='close_btn'
                    onClick={() => setModalOpen(false)}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6 18 18 6M6 6l12 12'
                      />
                    </svg>
                  </div>
                  <div className='formContent'>
                    <img src='/assets/logos/logo-colour.png' alt='logo' />
                    <p className='boxTitle'>{boxTitle}</p>
                    <p className='boxSubTitle'>{boxSubTitle}</p>
                  </div>
                  <div className='form-row'>
                    <div className='col-md-12 mb-2'>{render("name")}</div>
                  </div>
                  <div className='form-row'>
                    <div className='col-md-12 mb-2'>{render("email")}</div>
                  </div>
                  <div className='form-row'>
                    <div className='col-md-12 mb-2'>
                      <div className='actions'>
                        <Button onClick={onSubmit} status={post.status}>
                          {" "}
                          Sign Up{" "}
                        </Button>
                        <ErrorMessage
                          errors={form.errors}
                          summaryMessage='Please review the errors.'
                        />
                        <ErrorMessage errors={post.errors} />
                        {post.done() && (
                          <span
                            style={{
                              marginLeft: "1.5rem",
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            Successfully sent!
                          </span>
                        )}
                      </div>
                      <div className="actions2">
                        <Button onClick={() => doNotShow()}>Don't show again</Button>
                      </div>
                    </div>
                  </div>
                  
                  <p className='policy'>*Unsubscribe anytime.</p>
                  <p className='policy'>
                    By continuing, I agree to SA-Parks's{" "}
                    <a href='/privacy'>Privacy Policy</a>
                  </p>
                </div>
              </form>
            </div>
          )}
        </Portal>
      )}
    </div>
  );
}
