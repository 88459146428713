import React, { useEffect } from "react";
import env from "env";
import { usePost } from "components";
import { useForm } from "form";
import { Button, ErrorMessage } from "components";
import { getContactModel } from "./contactFormModel";
import { gtm } from "../../../lib/tracking";
import { useStore } from "../../../store";

export function Contact(props) {
  const emailReceiver = props.emailReceiver;
  const { state } = useStore();
  const model = getContactModel({ enquiryType: state.enquiryType });
  //const model = getContactModel({});
  const form = useForm(model, {
    usePlaceholder: true,
    onControlChanged: orderItemChanged,
  });
  //const form = useForm(model, { usePlaceholder: true});
  const [sendingStatus, setSendingStatus] = React.useState(null);
  //const [preParkNameFormAtdwPage, setPreParkNameFormAtdwPage] = React.useState();
  const [checkMsg, setCheckMsg] = React.useState(false);
  const [checkStart, setCheckStart] = React.useState(0);

  // setTimeout(() => {
  //   setPreParkNameFormAtdwPage(state.preParkName);
  // }, 1000);

  const post = usePost();

  function onSubmit(e) {
    form.validateForm(e, () => {
      const value = { ...form.getValue(), emailReceiver: emailReceiver };
      post.send(env.apiBase + "/api/contact/send", value);
      setSendingStatus("pending");
    });
  }

  function orderItemChanged(e, control) {
    if (control && control.name === "enquiryType") {
      // if(state.preParkName && state.preParkName !== ""){
      //   form.findControl("parkName").value = state.preParkName;
      // }
      form.findControl("parkName").value = state.preParkName;
      const newModel = getContactModel(form.getValue());
      form.reset(newModel);
      setCheckMsg(false);
    }
  }

  if (post.done() && sendingStatus === "pending") {
    var value = form.getValue();
    gtm.sendEvent("contactFormSubmitted", { contactEmail: value.email });
    form.reset(model);
    setSendingStatus(null);
    setCheckMsg(true);
  }

  const render = (name) => form.renderControl(name, null);
  const val = form.getValue();

  // useEffect(() => {
  //   console.log("contact page loop test4");
  //   if(!preParkNameFormAtdwPage){
  //     return;
  //   }
  //   form.findControl("parkName").value = preParkNameFormAtdwPage;
  //   // eslint-disable-next-line
  // },[preParkNameFormAtdwPage]);

  useEffect(() => {
    if (checkStart === 0) {
      console.log("state.enquiryType 1", state.enquiryType, form.getValue());
      setCheckStart(1);
      return;
    }
    form.reset(model);

    // eslint-disable-next-line
  }, [state.enquiryType]);

  // useLayoutEffect(() => {

  //   const _nameInput = document.querySelector("input[name='name'].form-control");
  //   if(_nameInput){
  //     _nameInput.blur();
  //   }

  //   // eslint-disable-next-line
  // }, []);

  return (
    <form className='contactForm' autoComplete='off'>
      <div className='form-row'>
        {/*<div className="col-md-6 mb-2 form__name">{render('lName')}</div>*/}
      </div>

      <div className='form-row'>
        <div className='col-md-4 mb-2 form__name'>{render("name")}</div>
        <div className='col-md-4 mb-2'>{render("phone")}</div>
        <div className='col-md-4 mb-2'>{render("email")}</div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2 enquiry__type'>
          {render("enquiryType")}
        </div>
      </div>

      {val.enquiryType === "parkBookingEnquiry" && (
        <div className='form-row'>
          <div className='col-md-12 mb-2'>{render("parkName")}</div>
        </div>
      )}

      {val.enquiryType === "orderCaravanCampingGuide" && (
        <div className='form-row'>
          <div className='col-md-12 mb-2'>{render("streetAddress")}</div>
          <div className='col-md-6 mb-2'>{render("postCode")}</div>
          <div className='col-md-6 mb-2'>{render("citySuburb")}</div>
        </div>
      )}

      <div className='form-row'>
        <div className='col-md-12 mb-2'>{render("enquiry")}</div>
        <div className='col-md-12 mb-2' style={{ textAlign: "center" }}>
          <span className='checkRobot'> {render("mailchimp")} </span>
        </div>
        <div className='col-md-12 mb-2' style={{ textAlign: "center" }}>
          <h5 className='contactFormPolicy'>
            By continuing, I agree to SA-Parks's{" "}
            <a href='/privacy'>Privacy Policy</a>
          </h5>
        </div>
      </div>

      <div className='form-row'>
        <div className='col-md-12 mb-2'>
          <div className='actions'>
            <Button onClick={onSubmit} status={post.status}>
              Send Message
            </Button>
            <ErrorMessage errors={post.errors} />
            {post.status === "done" && checkMsg && "Successfully sent!"}
          </div>
        </div>
      </div>
    </form>
  );
}
