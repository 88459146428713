import React, {useState, useEffect} from 'react';
import { SiteLink } from 'components';
import { useStore } from "store";

import { FaChevronDown } from 'react-icons/fa';
import { SearchBar } from './searchBar';
import { useAuth } from '../../auth/index';

export function NavBar(props) {
  const open = props.open;
  const currentLocation = props.currentLocation;
  const { state } = useStore();

  const _useAusth = useAuth();

  const menu = state.menu || { items: [] };
  const depth = 1;
  const menuItemElts = menu.items.map((item, index) =>
    <MenuItem item={item} key={index} depth={depth} onCloseMenu={props.onCloseMenu} currentLocation={currentLocation} _useAusth = {_useAusth}></MenuItem>
  );
  return (
    <div className={`navBar ${open ? 'navBar--on' : ''}`} id="sliding-menu">
      <SearchBar onCloseMenu={props.onCloseMenu}/>
      <div className="navBar__scrollArea" id="top-menu">
        <nav className="navBar_nav">
          <ul>
            {menuItemElts}

            {
              (_useAusth.session && !_useAusth.session.expired()) ?
              <li className={`navBar__item`}>
              <SiteLink className="navBar__link" to='/' onClick={()=>{
                props.onCloseMenu();
                _useAusth.signout();
                if(currentLocation === '/'){
                  window.location.reload(false);
                }
              }}>
                <span>LOG OUT</span>
              </SiteLink>
            </li> : null
            }

          </ul>
        </nav>
      </div>
      <div className = "navBAr__bottomArea">
        <img src = {'/assets/logos/logo.png'} alt = "menu_image"/>
      </div>
    </div>);
}

function MenuItem(props) {
  const [subMenuOpened, setSubMenuOpened] = useState(false);
  const item = props.item;
  const subItems = item.items || [];
  const currentLocation = props.currentLocation;

  const _useAusth = props._useAusth;

  const subItemsElts = subItems.length > 0? subItems.map((subItem, index) =>
    <SubMenuItem item={subItem} key={index} onCloseMenu={props.onCloseMenu} />
  ) : null;


  useEffect(() => {
    //console.log('navbar', currentLocation);
    setSubMenuOpened(false);
  }, [currentLocation])

  function closeMenu() {
    props.onCloseMenu();
  }

  function toggleSubmenu(e) {
    e.preventDefault();
    e.stopPropagation();
    setSubMenuOpened(!subMenuOpened);
  }
  
  return (
    item.title === 'MEMBERS HOME' ? (
      _useAusth.session && !_useAusth.session.expired() ?
      <li className={`navBar__item ${subMenuOpened ? 'navBar__item--open' : ''}`}>
      <SiteLink className="navBar__link" to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
        {subItemsElts && <span className="navBar__icon" onClick={(e) => toggleSubmenu(e)}><FaChevronDown /></span>}
      </SiteLink>
      {subItemsElts &&
        <ul className={`navBar__subMenu`}>
          {subItemsElts}
        </ul>}
    </li> : null
    ) : item.title === 'MEMBERS' && (_useAusth.session && !_useAusth.session.expired()) ? 
    null :
    <li className={`navBar__item ${subMenuOpened ? 'navBar__item--open' : ''}`}>
      <SiteLink className="navBar__link" to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
        {subItemsElts && <span className="navBar__icon" onClick={(e) => toggleSubmenu(e)}><FaChevronDown /></span>}
      </SiteLink>
      {subItemsElts &&
        <ul className={`navBar__subMenu`}>
          {subItemsElts}
        </ul>}
    </li>
  )
}

function SubMenuItem(props) {
  const item = props.item;
  function closeMenu() {
    props.onCloseMenu();
  }

  return (
    <li className="navBar__subItem">
      <SiteLink className="navBar__subLink" to={item.url} onClick={() => closeMenu()}>
        <span>{item.title}</span>
      </SiteLink>
    </li>
  )
}