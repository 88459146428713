/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { SiteLink } from 'components';
import { useState, useEffect } from 'react';
import env from 'env';

export function ForgotPassword(props){
    const [email, setEmail] = useState("");
    const [msg, setMsg] = useState(`By clicking "Reset Password" we will send a password reset link`);
    const [buttonTrigger, setButtonTrigger] = useState(0);

    //const post = usePost();
    function onSubmit(e) {
        e.preventDefault();   
        e.stopPropagation();
        setButtonTrigger(buttonTrigger + 1);
        //post.send(env.apiBase + '/api/profile/sendpasswordresetemail', {email : email} );
    }

    useEffect(() => {
        if(buttonTrigger === 0){
            return;
        }
        const fetchData = async () => {
            const res = await fetch(env.apiBase + '/api/profile/sendpasswordresetemail', {
                method : 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({email : email})
            });
            const data = await res.json();
            let msg = "Password reset email sent, check your inbox."
            if(data.errors.length > 0){
                msg = data.errors[0].errorMessage;
            }
            setMsg(msg);
        }
        fetchData();
        setButtonTrigger(0);
    }, [buttonTrigger, email]);

    return(
        <form css={style.formContainer} autoComplete="off" onSubmit = {onSubmit}>
            <h1 style={{textAlign:'center'}}>Reset Password</h1>
            <div className="form-group">
                <label htmlFor="username"> Email Address </label>
                <input className="form-control" type="email" required onChange={(e)=>{
                    setEmail(e.target.value);
                }} value={email} />
            </div>
            <div css={style.textContainer}>
                {msg}
            </div>
            <div className="form-group" style={{textAlign:'center'}}>
                <button css={style.buttonStyle} type="submit" className="btn btn-primary">
                    Reset Password
                </button>
            </div>
            <SiteLink to = "/members-login">
                Go back to Log in?
            </SiteLink>
        </form>
    )
}