/** @jsx jsx */
import { jsx } from '@emotion/core'
import style from './style';

import SimpleReactLightbox, {SRLWrapper}  from 'simple-react-lightbox'
//import utils from 'utils';
//import cmsUtils from 'cms/utils/cmsUtils';

const options = {
    enablePanzoom : false,
    showCaption : false
};

export function PhotoGallery(props){
    const multimedia2 = props.item2;
    const cssClass = 'atdw_photoGallery';
    return(
        <div css={style.photoGallery} className={cssClass} >
            <h3 style={{marginTop: '0px', marginBottom: '0.5rem'}}> Gallery : </h3>
            <SimpleReactLightbox>
                <div css = {style.photos} className="photos"> 
                    <SRLWrapper options = {options}>
                        {/* <div css = {style.photoContainer}>
                            <img css = {style.photo} src="https://dummyimage.com/2560x800/333333/fff" alt="Caption" />
                        </div>
                        <div css = {style.photoContainer}>
                            <img css = {style.photo} src="https://dummyimage.com/2560x800/333333/fff" alt="Caption" />
                        </div> */}
                        { 
                            multimedia2.map((item, index) => {
                                return(
                                    <div css = {style.photoContainer} key = {index} >
                                        <img css = {style.photo} src = {item[0].serverPath}  alt={item[0].altText}/>
                                    </div>
                                )
                            })
                        }
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                        <div css = {style.photoEmpty}></div>
                    </SRLWrapper>
                </div>
            </SimpleReactLightbox>
        </div> 
    );   
}