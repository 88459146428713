/** @jsx jsx */
import { jsx } from "@emotion/core";
//import style from './style';
import utils from "utils";
import { AtdwSearchOption } from "../searchOption/view";
import { AtdwSearchResult } from "../searchResult/view";
import cmsUtils from "cms/utils/cmsUtils";
import { useState, useEffect, useCallback, useContext } from "react";
import env from "env";
import { AtdwContext } from "../index";

function AtdwSearchTitle(props) {
  const type = props.type;
  //console.log("type",type);
  const title = { sTitle: "", title: "" };
  if (type === "ACCOMM") {
    title.sTitle = "SEARCH & BOOK";
    title.title = "ACCOMMODATION";
  } else if (type === "ATTRACTION") {
    title.sTitle = "SEARCH";
    title.title = "ATTRACTIONS";
  } else if (type === "DESTINFO") {
    title.sTitle = "SEARCH";
    title.title = "TOWN";
  } else if (type === "RESTAURANT") {
    title.sTitle = "SEARCH";
    title.title = "RESTAURANT";
  } else if (type === "EVENT") {
    title.sTitle = "SEARCH";
    title.title = "EVENT";
  } else if (type === "INFO") {
    title.sTitle = "SEARCH";
    title.title = "INFO";
  } else if (type === "TOUR") {
    title.sTitle = "SEARCH";
    title.title = "TOUR";
  } else if (type === "GENSERVICE") {
    title.sTitle = "SEARCH";
    title.title = "GENSERVICE";
  } else if (type === "JOURNEY") {
    title.sTitle = "SEARCH";
    title.title = "JOURNEY";
  }

  return (
    <h3 className='atdw__title'>
      <strong>{title.sTitle}</strong> {title.title}
    </h3>
  );
}

export function AtdwSearch(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames(
    "cms_item",
    "atdwSearch",
    item.cssClass || item.anchorName || ""
  );
  const bgColor = cmsUtils.payload(item, "BgColor");
  const imageUrl = cmsUtils.payload(item, "ImageUrl");
  const backgroundTile = cmsUtils.payload(item, "BackgroundTile");
  const petFriendly = cmsUtils.payload(item, "PetFriendly") || "off";
  const tilesSize = 12;
  const atdwArea = cmsUtils.payload(item, "ProductArea") || "all";
  const customCategories = cmsUtils.payload(item, "CustomCategories") || "";

  //const atdwArea = "all";

  let bgUrl = null;
  if (imageUrl) bgUrl = utils.site.resourcePath(imageUrl);
  else if (backgroundTile)
    bgUrl = utils.site.resourcePath(
      `/dist/transparent-textures/textures/${backgroundTile}`
    );

  const [atdwState, setAtdwState] = useState({
    atdwType: "ACCOMM",
    atdwArea: atdwArea,
    index: 1,
    atdwDataFromDB: [],
    reserveAtdwData: [],
    shuffledAtdwData: [],
    atdwData: [],
    trigger: 0,
    context: useContext(AtdwContext),
  });

  //const [atdwSearchText, setAtdwSearchText] = useState("Searching for ATDW");
  const [atdwSearchText, setAtdwSearchText] = useState(" ");

  //TXA
  const [trigger, setTrigger] = useState(0);
  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [numOfAdults, setNumOfAdults] = useState("");
  const [numOfKids, setNumOfKids] = useState("");

  //checkIn, checkOut, numOfAdults, numOfKids,
  function searechAtdwFilter({
    petFriendly,
    town,
    name,
    subType,
    atdwDataItem,
    customCategories,
  }) {
    //const _checkIn = checkIn || '';
    //const _checkOut = checkOut || '';
    //const _numOfAdults = numOfAdults || '2';
    //const _numOfKids = numOfKids || '0';
    const _petFriendly = petFriendly || false;
    const _customCategories = customCategories || "";

    const _town = town || "";
    const _name = name || "";
    const _subType = subType || [];

    const _filteredData = atdwDataItem.filter((data) => {
      let returnResult = false;
      //1
      if (_subType.length > 0) {
        //checkFilterDo = true
        for (var i in data.additionalData.PRODUCTTYPES) {
          returnResult = false;
          if (
            subType
              .toString()
              .includes(data.additionalData.PRODUCTTYPES[i].productTypeId)
          ) {
            returnResult = true;
          } else {
            returnResult = false;
            break;
          }
        }
      } else {
        returnResult = true;
      }

      //2
      if (returnResult === true && _town !== "" && _town !== null) {
        //checkFilterDo = true
        returnResult = false;
        if (data.town.toLowerCase().includes(_town.toLowerCase())) {
          returnResult = true;
        }
      }

      //3
      if (returnResult === true && _name !== "" && _name !== null) {
        //checkFilterDo = true
        returnResult = false;
        if (data.productName.toLowerCase().includes(_name.toLowerCase())) {
          returnResult = true;
        }
      }

      //4 pet
      if (returnResult === true && _petFriendly === true) {
        //checkFilterDo = true
        returnResult = false;
        const _checkPetFriendly = data.checkPetFriendly || false;
        if (_checkPetFriendly === true) {
          returnResult = true;
        }
      }

      //5 customCategories
      if (
        returnResult === true &&
        _customCategories !== null &&
        _customCategories !== ""
      ) {
        returnResult = false;
        if (data.customCategories && data.customCategories !== "") {
          const _customCategoryArray = _customCategories.split(",");
          for (let i in _customCategoryArray) {
            returnResult = false;
            if (
              data.customCategories
                .toLowerCase()
                .includes(_customCategoryArray[i].toLowerCase())
            ) {
              returnResult = true;
              break;
            }
          }
        }
      }
      return returnResult;
    });
    return _filteredData;
  }

  function onGetSearchOptionFun(value) {
    const _checkIn = value.checkIn ? value.checkIn : "";
    const _checkOut = value.checkOut ? value.checkOut : "";
    const _numOfAdults = value.numOfAdults.value
      ? value.numOfAdults.value
      : "2";
    const _numOfKids = value.numOfKids.value ? value.numOfKids.value : "0";
    const _petFriendly = value.petFriendly ? value.petFriendly : false;

    const subType = [];
    for (var i in value.subType) {
      subType.push(value.subType[i].value);
    }
    const town = value.town.trim();
    const name = value.name.trim();

    //let checkFilterDo = false

    const _filteredData = searechAtdwFilter({
      petFriendly: _petFriendly,
      town: town,
      name: name,
      subType: subType,
      atdwDataItem: atdwState.shuffledAtdwData,
      customCategories: customCategories,
      //checkFilterDo : checkFilterDo
    });

    const _data = [];

    if (_filteredData.length > 0) {
      for (let x = 0; x < tilesSize; x++) {
        _data.push(_filteredData[x]);
        if (x === _filteredData.length - 1) {
          break;
        }
      }
    }

    setAtdwState((pre) => {
      let _new = { ...pre };
      _new.context.setupAtdwTxaCheckInDate(null);
      _new.reserveAtdwData = _filteredData;

      if (_checkIn !== "") {
        _new.atdwData = [];
        setCheckIn(_checkIn);
        setCheckOut(_checkOut);
        setNumOfAdults(_numOfAdults);
        setNumOfKids(_numOfKids);
        setTrigger(trigger + 1);
        //setAtdwSearchText("Searching for ATDW");
        setAtdwSearchText(" ");
        _new.context.setupAtdwTxaCheckInDate(_checkIn);
      } else {
        _new.atdwData = _data;
        _new.index = 1;
        _new.context.setupAtdwDataHistory({
          atdwDataFromDB: _new.atdwDataFromDB,
          reserveAtdwData: _new.reserveAtdwData,
          atdwData: _new.atdwData,
          index: _new.index,
        });
        if (_data.length === 0) {
          setAtdwSearchText(
            "Currently no match, please check in the Regions below to find more Park options for your preferred dates."
          );
        } else {
          setAtdwSearchText("");
        }
      }
      return _new;
    });
  }

  // const onGetSearchOptionFun = ((value) => {

  //     const _checkIn = value.checkIn ? value.checkIn : '';
  //     const _checkOut = value.checkOut ? value.checkOut : '';
  //     const _numOfAdults = value.numOfAdults.value ? value.numOfAdults.value : '2';
  //     const _numOfKids = value.numOfKids.value ? value.numOfKids.value : '0';
  //     const _petFriendly = value.petFriendly ? value.petFriendly : false;

  //     const subType = [];
  //     for(var i in value.subType){
  //         subType.push(value.subType[i].value);
  //     }
  //     const town = value.town.trim();
  //     const name = value.name.trim();

  //     let checkFilterDo = false
  //     const _filteredData = atdwState.atdwDataFromDB.filter((data) => {

  //         //console.log("data", data);

  //         let returnResult = false;

  //         //1
  //         if(subType.length > 0){
  //             checkFilterDo = true
  //             for(var i in data.additionalData.PRODUCTTYPES){
  //                 returnResult = false;
  //                 if(subType.toString().includes(data.additionalData.PRODUCTTYPES[i].productTypeId)){
  //                     returnResult = true;
  //                 }
  //                 else{
  //                     returnResult = false;
  //                     break;
  //                 }
  //             }
  //         }
  //         else{
  //             returnResult = true;
  //         }

  //         //2
  //         if(returnResult === true && (town !== "" && town !== null)){
  //             checkFilterDo = true
  //             returnResult = false;
  //             if(data.town.toLowerCase().includes(town.toLowerCase())){
  //                 returnResult = true;
  //             }
  //         }

  //         //3
  //         if(returnResult === true && (name !== "" && name !== null)){
  //             checkFilterDo = true
  //             returnResult = false;
  //             if(data.productName.toLowerCase().includes(name.toLowerCase())){
  //                 returnResult = true;
  //             }
  //         }

  //         //4 pet
  //         if(returnResult === true && (_petFriendly === true)){
  //             checkFilterDo = true
  //             returnResult = false;
  //             const _checkPetFriendly = data.checkPetFriendly || false;
  //             if(_checkPetFriendly === true){
  //                 returnResult = true;
  //             }
  //         }

  //         return returnResult;
  //     });

  //     const _data = [];

  //     if(checkFilterDo){
  //         if(_filteredData.length > 0){
  //             for(let x = 0; x < tilesSize; x++){
  //                 _data.push(_filteredData[x]);
  //                 if(x === (_filteredData.length -1)){
  //                     break;
  //                 }
  //             }
  //         }
  //     }
  //     else{
  //         if(atdwState.shuffledAtdwData.length > 0){
  //             for(let x = 0; x < tilesSize; x++){
  //                 _data.push(atdwState.shuffledAtdwData[x]);
  //                 if(x === (atdwState.shuffledAtdwData.length -1)){
  //                     break;
  //                 }
  //             }
  //         }
  //     }

  //     setAtdwState(pre => {
  //         let _new = {...pre};
  //         _new.context.setupAtdwTxaCheckInDate(null);

  //         if(checkFilterDo){
  //             _new.reserveAtdwData = _filteredData;
  //         }
  //         else{
  //             _new.reserveAtdwData = atdwState.shuffledAtdwData;
  //         }

  //         if(_checkIn !== ''){
  //             _new.atdwData = [];
  //             setCheckIn(_checkIn);
  //             setCheckOut(_checkOut);
  //             setNumOfAdults(_numOfAdults);
  //             setNumOfKids(_numOfKids);
  //             setTrigger(trigger + 1);
  //             //setAtdwSearchText("Searching for ATDW");
  //             setAtdwSearchText(" ");
  //             _new.context.setupAtdwTxaCheckInDate(_checkIn);
  //         }
  //         else{
  //             _new.atdwData = _data;
  //             _new.index = 1;
  //             _new.context.setupAtdwDataHistory({
  //                 atdwDataFromDB : _new.atdwDataFromDB,
  //                 reserveAtdwData : _new.reserveAtdwData,
  //                 atdwData : _new.atdwData,
  //                 index : _new.index
  //             });
  //             if(_data.length === 0){
  //                 setAtdwSearchText("Sorry, there are no available results.");
  //             }
  //             else{
  //                 setAtdwSearchText("");
  //             }
  //         }
  //         return _new;
  //     });
  // });

  useEffect(() => {
    //console.log("useEffect_1");
    if (atdwState.context.atdwDataHistory2 !== null) {
      setAtdwState((pre) => {
        let _new = { ...pre };
        _new.atdwDataFromDB = atdwState.context.atdwDataHistory.atdwDataFromDB;
        _new.reserveAtdwData =
          atdwState.context.atdwDataHistory.reserveAtdwData;
        _new.atdwData = atdwState.context.atdwDataHistory.atdwData;
        _new.index = atdwState.context.atdwDataHistory.index;
        return _new;
      });
      setTimeout(() => {
        document
          .getElementById(atdwState.context.atdwDataHistory2)
          .scrollIntoView({ behavior: "smooth", block: "center" });
        atdwState.context.setupAtdwDataHistory2(null);
        atdwState.context.setupAtdwTxaCheckInDate(null);
      }, 500);

      return;
    }

    const fetchData = async () => {
      const res = await fetch(
        env.apiBase + "/api/atdw/getproducts/" + atdwState.atdwArea
      );
      const data = await res.json();

      const _data = [];

      data.products.forEach((el) => {
        const _attributes = el.additionalData.attributes || [];
        let checkPetFriendly = false;
        for (let _a in _attributes) {
          if (_attributes[_a].attributeId === "PETALLOW") {
            checkPetFriendly = true;
            break;
          }
        }
        el.checkPetFriendly = checkPetFriendly;
      });

      var shuffled = JSON.parse(JSON.stringify(data.products));
      var currentIndex = shuffled.length,
        temporaryValue,
        randomIndex;

      while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = shuffled[currentIndex];
        shuffled[currentIndex] = shuffled[randomIndex];
        shuffled[randomIndex] = temporaryValue;
      }

      //backup
      // for(var i = 0; i < tilesSize; i++){
      //     _data.push(data.products[i]);
      //     if(i === (data.products.length -1)){
      //         break;
      //     }
      // }
      let _filteredData = shuffled;

      // if(petFriendly && petFriendly === 'on'){
      //     _filteredData = searechAtdwFilter({petFriendly : true, atdwDataItem : _filteredData});
      // }

      _filteredData = searechAtdwFilter({
        petFriendly: petFriendly && petFriendly === "on" ? true : false,
        customCategories:
          customCategories && customCategories !== "" ? customCategories : "",
        atdwDataItem: _filteredData,
      });

      //shuffled
      if (_filteredData.length > 0) {
        for (var i = 0; i < tilesSize; i++) {
          _data.push(_filteredData[i]);
          if (i === _filteredData.length - 1) {
            break;
          }
        }
      }

      setAtdwState((pre) => {
        let _new = { ...pre };
        _new.atdwDataFromDB = data.products;
        _new.reserveAtdwData = _filteredData;
        _new.shuffledAtdwData = shuffled;
        _new.atdwData = _data;
        _new.context.setupAtdwDataHistory({
          atdwDataFromDB: _new.atdwDataFromDB,
          reserveAtdwData: _new.reserveAtdwData,
          atdwData: _new.atdwData,
          index: _new.index,
        });
        return _new;
      });

      if (_data.length > 0) {
        setAtdwSearchText("");
      } else {
        setAtdwSearchText("Sorry, there are no available results.");
      }
    };
    fetchData();

    // eslint-disable-next-line
  }, [atdwState.atdwType, atdwState.context, atdwState.atdwArea]);

  useEffect(() => {
    if (trigger === 0) {
      return;
    }

    const r_data = [];
    const _data = [];
    const fetchData = async () => {
      const sendData = {
        checkIn: checkIn,
        checkOut: checkOut,
        adults: numOfAdults,
        kids: numOfKids,
        txaList: [],
      };
      atdwState.reserveAtdwData.forEach((el) => {
        // if(el.additionalData.externalSystems.length > 0){
        //     for(var i in el.additionalData.externalSystems){
        //         if(el.additionalData.externalSystems[i].externalSystemCode === "TXA_DEFAULT"){
        //             sendData.txaList.push(el.additionalData.externalSystems[i].externalSystemText);
        //             break;
        //         }
        //         else if(el.additionalData.externalSystems[i].externalSystemCode === "TXA_MULTI" && env.MultiTxaCode.includes(el.additionalData.externalSystems[i].externalSystemText)){
        //             sendData.txaList.push(el.additionalData.externalSystems[i].externalSystemText);
        //             break;
        //         }
        //         else if(el.additionalData.externalSystems[i].externalSystemCode === "TXA_CODE" && env.MultiTxaCode.includes(el.additionalData.externalSystems[i].externalSystemText)){
        //             sendData.txaList.push(el.additionalData.externalSystems[i].externalSystemText);
        //             break;
        //         }
        //     }
        // }
        if (el.txaCode && el.txaCode !== "") {
          sendData.txaList.push(el.txaCode);
        }
      });

      //BelairNatParkCP
      // if(!sendData.txaList.toString().includes("BelairNatParkCP")){
      //     sendData.txaList.push("BelairNatParkCP");
      // }

      //console.log('aaa', sendData);

      const res = await fetch(env.apiBase + "/api/atdw/gettxaproducts", {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sendData),
      });
      const data = await res.json();
      console.log("dddd ---- ", data);

      let _count = 0;
      for (var i = 0; i < atdwState.reserveAtdwData.length; i++) {
        // for(var n = 0; n < atdwState.reserveAtdwData[i].additionalData.externalSystems.length; n++){
        //     if(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemCode === "TXA_DEFAULT"){
        //         //console.log(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText);
        //         if(data.toString().includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){
        //             r_data.push(atdwState.reserveAtdwData[i]);
        //             if(_count < tilesSize){
        //                 _data.push(atdwState.reserveAtdwData[i]);
        //             }
        //             _count++;
        //             break;
        //         }
        //     }
        //     else if(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemCode === "TXA_MULTI" &&
        //         env.MultiTxaCode.includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){

        //             if(data.toString().includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){
        //                 r_data.push(atdwState.reserveAtdwData[i]);
        //                 if(_count < tilesSize){
        //                     _data.push(atdwState.reserveAtdwData[i]);
        //                 }
        //                 _count++;
        //                 break;
        //             }
        //     }
        //     else if(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemCode === "TXA_CODE" &&
        //         env.MultiTxaCode.includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){

        //             if(data.toString().includes(atdwState.reserveAtdwData[i].additionalData.externalSystems[n].externalSystemText)){
        //                 r_data.push(atdwState.reserveAtdwData[i]);
        //                 if(_count < tilesSize){
        //                     _data.push(atdwState.reserveAtdwData[i]);
        //                 }
        //                 _count++;
        //                 break;
        //             }
        //     }
        // }
        ////BelairNatParkCP
        // if(data.toString().includes("BelairNatParkCP") && atdwState.reserveAtdwData[i].productId === '56b2711faeeeaaf773d03794'){
        //     r_data.push(atdwState.reserveAtdwData[i]);
        //     if(_count < tilesSize){
        //         _data.push(atdwState.reserveAtdwData[i]);
        //     }
        //     _count++;
        // }

        if (
          atdwState.reserveAtdwData[i].txaCode !== null &&
          atdwState.reserveAtdwData[i].txaCode !== ""
        ) {
          if (data.toString().includes(atdwState.reserveAtdwData[i].txaCode)) {
            r_data.push(atdwState.reserveAtdwData[i]);
            if (_count < tilesSize) {
              _data.push(atdwState.reserveAtdwData[i]);
            }
            _count++;
          }
        }
      }

      setAtdwState((pre) => {
        let _new = { ...pre };
        _new.reserveAtdwData = r_data;
        _new.atdwData = _data;
        _new.index = 1;
        return _new;
      });

      if (_data.length === 0) {
        setAtdwSearchText("Sorry, there are no available results.");
      } else {
        setAtdwSearchText("");
      }
    };

    fetchData();
    setTrigger(0);
  }, [
    trigger,
    checkIn,
    checkOut,
    numOfAdults,
    numOfKids,
    atdwState.reserveAtdwData,
  ]);

  const addAtdw = useCallback(
    (reserveAtdwData, _atdwContext) => {
      //console.log("addAtdw", reserveAtdwData);
      const av = [];
      for (
        var i = atdwState.index * tilesSize;
        i < atdwState.index * tilesSize + tilesSize;
        i++
      ) {
        av.push(reserveAtdwData[i]);
        if (reserveAtdwData.length - 1 === i) {
          break;
        }
      }

      setAtdwState((pre) => {
        let _new = { ...pre };
        _new.atdwData = [..._new.atdwData, ...av];
        _new.index = _new.index + 1;
        _atdwContext.setupAtdwDataHistory({
          atdwDataFromDB: _new.atdwDataFromDB,
          reserveAtdwData: _new.reserveAtdwData,
          atdwData: _new.atdwData,
          index: _new.index,
        });
        return _new;
      });
    },
    [atdwState.index]
  );

  return (
    <section
      style={{
        backgroundColor: `${bgColor}`,
        backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl),
        padding: "1rem 0px",
      }}
      className={cssClass}
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId}
      id={item.anchorName}
    >
      <div className='container'>
        <AtdwSearchTitle type={atdwState.atdwType} />
        <AtdwSearchOption
          atdwType={atdwState.atdwType}
          onGetSearchOption={onGetSearchOptionFun}
          petFriendly={petFriendly}
        />

        {atdwSearchText === "" && (
          <div style={{ marginLeft: "1rem" }}>
            <p style={{ marginBottom: "0.3rem" }}>
              You’re in luck! The following Parks have availability for your
              dates.
            </p>
            1 to {atdwState.atdwData.length} of{" "}
            {atdwState.reserveAtdwData.length}
          </div>
        )}

        {atdwState.atdwData ? (
          <AtdwSearchResult atdwData={atdwState.atdwData} />
        ) : null}

        {atdwState.reserveAtdwData.length >= 12 && atdwSearchText === "" ? (
          <div style={{ marginRight: "1rem", textAlign: "right" }}>
            1 to {atdwState.atdwData.length} of{" "}
            {atdwState.reserveAtdwData.length}
          </div>
        ) : null}

        {atdwState.atdwData.length !== atdwState.reserveAtdwData.length &&
        atdwState.atdwData.length > 0 ? (
          <div style={{ textAlign: "center" }}>
            <button
              className='btn btn-primary'
              type='button'
              onClick={() => {
                addAtdw(atdwState.reserveAtdwData, atdwState.context);
              }}
            >
              MORE
            </button>
          </div>
        ) : null}

        {atdwSearchText !== "" && (
          <div style={{ textAlign: "center", margin: "1rem 0px" }}>
            {atdwSearchText}
          </div>
        )}
      </div>
    </section>
  );
}
