import React, { useState, useEffect, useContext, createContext } from "react";
import * as firebase from "firebase/app";
import "firebase/auth";
import config from '../../../config';

// Add your Firebase credentials
firebase.initializeApp(config.firebaseSettings);

const authContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [session, setSession] = useState(null);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signin = (email, password) => {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(response => {
        //setUser(response.user);
        setUserSession(response.user);
        return response.user;
      });
  };

  const signup = (email, password) => {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(response => {
        //setUser(response.user);
        setUserSession(response.user);
        return response.user;
      });
  };

  const signout = () => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        //setUser(null);
        setUserSession(null);
      });
  };

  const sendPasswordResetEmail = email => {
    return firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        return true;
      });
  };

  const confirmPasswordReset = (code, password) => {
    return firebase
      .auth()
      .confirmPasswordReset(code, password)
      .then(() => {
        return true;
      });
  };

  async function setUserSession(user) {
    if (!user) {
      localStorage.setItem('user', null);
      setUser(null);
      setSession(null);
      return;
    }
    const tokenResult = await user.getIdTokenResult(/* forceRefresh */ false);
    console.log('setting sessions...', tokenResult.expirationTime);
    let session = wrapUser(user, tokenResult);
    localStorage.setItem('user', JSON.stringify(session));
    setUser(user);
    setSession(session);
  
    // const ajaxResult = await this.getUserProfile(user.uid).toPromise();
    // const errors = formUtils.getErrors(ajaxResult);
    // if (errors.length > 0) throw errors.join(" ");
    // //console.log(ajaxResult, userJson);
    // userJson.profileCheched = true;
    // if (ajaxResult.results && ajaxResult.results.userInfo && ajaxResult.results.userInfo.roles)
    //   userJson.roles = ajaxResult.results.userInfo.roles
    return session;
  }
  
  async function extendSession() {
    console.log('checking sessions...')
    if (session && session.expireAt) {
      if (user) {
        let now = new Date();
        //console.log(this.currentUser, ((+new Date(this.currentUser.expireAt)) - +now) / 60 / 1000)
        if ((+new Date(session.expireAt)) - +now < 11/*min*/ * 60 * 1000) {
          console.log('extending sessions...')
          const tokenResult = await user.getIdTokenResult(/* forceRefresh */ false);
          //console.log(tokenResult);
          let session = wrapUser(user, tokenResult);
          localStorage.setItem('user', JSON.stringify(session));
          setUser(user);
          setSession(session);
        }
      }

    }
  }

  function wrapUser(user, tokenResult) {
    return {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoUrl: user.photoURL,
      emailVerified: user.emailVerified,
      profileCheched: false,
      token: tokenResult ? tokenResult.token : null,
      roles: [],
      //refreshToken: user.refreshToken,
      expireAt: tokenResult ? tokenResult.expirationTime : null,
      expired() {
        return (+new Date(this.expireAt)) - +new Date() < 0
      }
    };
  }  

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        //setUser(user);
        setUserSession(user);
      } else {
        //setUser(null);
        setUserSession(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
    // eslint-disable-next-line 
  }, []);

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      console.log('starting session interval')
      let timer = setInterval(() => extendSession(), 5/*min*/ * 60 * 1000)
      return () => { clearInterval(timer) }
    }
    // eslint-disable-next-line 
  }, [])


  // Return the user object and auth methods
  return {
    user,
    session,
    signin,
    signup,
    signout,
    sendPasswordResetEmail,
    confirmPasswordReset
  };
}