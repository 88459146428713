/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment, useRef } from "react";
import env from "env";
import { useRouter, NotFound } from "components";
import Slider from "react-slick";
import style from "./style";
import { MdDirections, MdCall, MdWeb } from "react-icons/md";
import { FaCircle } from "react-icons/fa";
//import { SiteLink } from 'components';
//import Collapsible from 'react-collapsible';
import { ViewPartial } from "cms/pages/partials/viewPartial";
//import { MetaTag } from 'components';
//import utils from 'utils';
import { PhotoGallery } from "../photoGallery/view";
import { ServiceTile } from "../serviceTile/view";
import { Loading, MetaTag } from "components";
import { gtm } from "../../../lib/tracking";
import utils from "../../../utils";
import { useStore } from "../../../store";

function AtdwService(props) {
  const services = props.services;
  const atdwName = props.atdwName;
  const slugs = props.slugs;
  const txacheckindate = props.txacheckindate;

  const getSlugName = (serviceName) => {
    for (var i in slugs) {
      if (slugs[i].serviceName === serviceName) {
        return slugs[i];
      }
    }
  };

  return (
    <div
      className='serviceResult'
      style={{ marginLeft: "-5px", marginRight: "-5px" }}
    >
      <div css={style.serviceTiles} className='serviceTiles'>
        {services.map((item, index) => (
          <ServiceTile
            service={item}
            atdwName={atdwName}
            key={index}
            slug={getSlugName(item.serviceName)}
            txacheckindate={txacheckindate}
          />
        ))}
      </div>
    </div>
  );
}

function AtdwPageBaner(props) {
  const multimedia = props.multimedia;
  const primaryPhone = props.primaryPhone;
  const siteUrl = props.siteUrl;
  const bookingUrl = props.bookingUrl || "";

  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  //media={'(min-width:' + _item.width + 'px)'}
  return (
    <section>
      <Slider {...settings}>
        {multimedia.map((item, index) => {
          return (
            <div css={style.tileSize} className='tileSize' key={index}>
              <picture>
                {item.map((_item, _index) => {
                  return (
                    <source
                      key={_index}
                      crossOrigin='anonymous'
                      srcSet={_item.serverPath}
                      media={_item.width <= 800 ? "(min-width:600px)" : null}
                    />
                  );
                })}
                <img src='/assets/logos/logo2.png' alt='image2' />
              </picture>
            </div>
          );
        })}
      </Slider>
      <div
        css={style.banerIconsBar}
        className='atdw__page__banerIconsBar atdw__item'
        id='atdwpagebanericonsbar'
      >
        {primaryPhone === "" ? null : (
          <a href={"tel:" + primaryPhone} className='bar__item'>
            <MdCall />
            <div className='icon__text'>PHONE</div>
          </a>
        )}

        <div
          className='bar__item'
          onClick={() => {
            document
              .getElementById("atdwPageLocation")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }}
        >
          <MdDirections />
          <div className='icon__text'>LOCATION</div>
        </div>

        {siteUrl === "" ? null : (
          <a
            href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
            className='bar__item'
            target='_blank'
            rel='noopener noreferrer'
          >
            <MdWeb />
            <div className='icon__text'>WEBSITE</div>
          </a>
        )}

        {bookingUrl === "" ? null : (
          <a
            href={
              bookingUrl.includes("http") ? bookingUrl : "https://" + bookingUrl
            }
            css={style.booking__button}
            className='bar__item booking__button'
            target='_blank'
            rel='noopener noreferrer'
          >
            {/* <MdBook /> */}
            <img
              css={style.booking_button_image}
              src='/assets/book-now-icon.png'
              alt='booking_icon'
            />
            <div className='icon__text'>BOOKINGS</div>
          </a>
        )}
      </div>
    </section>
  );
}

// function AtdwContactDetails(props){
//     const communication = props.communication;
//     return (
//         <Collapsible trigger="Contact Details">
//             {
//                 communication.map((item, index) => {
//                   return(
//                       <p key={index}>
//                           {item.attributeIdCommunicationDescription + ' : ' + item.communicationDetail}
//                       </p>
//                   )
//                 })
//             }
//         </Collapsible>
//     )
// }

// function AtdwDateTime(props){
//     const dates = props.dates;
//     const todayDate = new Date();
//     return (

//         dates.length > 0 ? (
//             <Collapsible trigger="Date &amp; Time">
//             <div className="row">
//                 {
//                     dates.map((item, index) => {
//                         var pDate = new Date(item.frequencyStartDate);
//                         if( pDate >= todayDate){
//                             return(
//                                 <div className="col-6 col-sm-4" key={index} style={{fontFamily:'sans-serif'}}>
//                                     {
//                                         ((''+ pDate.getDate()).length < 2 ? '0' + pDate.getDate() : ''+pDate.getDate()) + '-' +
//                                             ((''+(pDate.getMonth() + 1)).length < 2 ? '0' + (pDate.getMonth() + 1) : ''+(pDate.getMonth() + 1)) + '-' +
//                                             pDate.getFullYear() + ' : ' +
//                                             item.frequencyStartTime
//                                     }
//                                 </div>
//                             )
//                         }
//                         return null;
//                     })
//                 }
//             </div>
//         </Collapsible>
//         ):null
//     )
// }

function AtdwAttributes(props) {
  const features = props.features;
  console.log("AtdwAttributes ->", features);
  return (
    <Fragment>
      <h3 style={{ marginTop: "0px", marginBottom: "0.5rem" }}> Features </h3>
      <div className='row' style={{ paddingLeft: "1rem" }}>
        {features.map((item, index) => {
          return (
            <div className='col-12 col-sm-4' key={index}>
              <FaCircle
                style={{
                  height: "0.2em",
                  width: "0.2em",
                  verticalAlign: "middle",
                }}
              />
              <span> {item.attributeIdDescription} </span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}

function AtdwLocation(props) {
  const productName = props.productName;
  const geoCode = props.geoCode;
  const address = props.address;
  const postCode = props.postCode;
  //const _address = (address + '+SA+' + postCode).replace(/\s+/g, '+');
  const primaryPhone = props.primaryPhone;
  const email = props.email;
  const siteUrl = props.siteUrl;
  const flag = props.flag;
  const ele = useRef(null);
  const bookingUrl = props.bookingUrl;

  useEffect(() => {
    var map, marker;
    map = new window.google.maps.Map(ele.current, {
      center: { lat: geoCode.lat, lng: geoCode.lng },
      zoom: 15,
    });
    marker = new window.google.maps.Marker({
      position: { lat: geoCode.lat, lng: geoCode.lng },
      map: map,
      title: productName,
    });
    console.log(marker);
  }, [productName, geoCode]);
  //const result = [];
  const getFlag = () => {
    let result = [];
    for (var i in flag) {
      if (flag[i].attributeId === "BIG4") {
        result.push(
          <li key={i}>
            <img src='/assets/icons/big4.jpg' alt='image2' />
          </li>
        );
      }
    }
    return result.length > 0 ? (
      <div css={style.flag}>
        <ul>{result}</ul>
      </div>
    ) : null;
  };

  return (
    <Fragment>
      <h3 css={style.contact_text}>{"Contact " + productName}</h3>
      <h4> {address + " SA " + postCode}</h4>
      <div>
        {/* {'GPS: South ' + geoCode.lat + ' East ' + geoCode.lng} */}
        <span css={style.gps_text}> {"GPS: South " + geoCode.lat} </span>
        <span css={style.gps_text} style={{ marginLeft: "0.5rem" }}>
          {" "}
          {"East " + geoCode.lng}{" "}
        </span>
      </div>
      <div css={style.info}>
        <ul>
          <li>
            <a href={"tel:" + primaryPhone}>{primaryPhone}</a>
          </li>
          <li>
            <a href={"mailto:" + email}>Email Us</a>
          </li>
          <li>
            <a
              href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {"Visit The " + productName + " Website"}
            </a>
          </li>
        </ul>
      </div>
      {getFlag()}

      {bookingUrl === "" ? null : (
        <div style={{ marginTop: "1rem", marginBottom: "1.3rem" }}>
          <a
            href={
              bookingUrl.includes("http") ? bookingUrl : "https://" + bookingUrl
            }
            className='button'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>
              {" "}
              <span> BOOK NOW </span>{" "}
            </strong>
          </a>
        </div>
      )}

      <div
        id='atdwPageLocation'
        style={{ height: "450px", marginTop: "1rem", marginBottom: "1rem" }}
      >
        <div ref={ele} style={{ height: "100%" }}></div>
        {/* <iframe
                    width="100%"
                    height="450"
                    frameBorder="0" style={{border:'0'}}
                    title={ productName }
                    src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY&q=' + _address} allowFullScreen>
                </iframe> */}
      </div>
    </Fragment>
  );
}

function getMetaTag(product, slug) {
  gtm.pageView("/atdwproduct/" + slug);
  return {
    title: product.productName,
    description: product.productShortDescription,
    keywords: "",
    heroImageUrl: product.multimedia[0].serverPath,
    language: "English",
    country: "Australia",
    siteName: "Light Regional Council",
    url: utils.site.resourcePath("/atdwproduct/" + slug),
    //canonical: utils.site.resourcePath(pageData.pageUrl)
  };
}

export function AtdwPage() {
  //const AtdwPageContext = React.createContext(null);
  const { dispatch } = useStore();
  const slug = useRouter().query.slug;
  const [atdwProduct, setAtdwProduct] = useState({
    product: null,
    serviceSlugs: null,
    atdwEx: null,
    primaryPhone: "",
    address: "",
    postCode: "",
    email: "",
    siteUrl: "",
    bookingUrl: "",
    geoCode: { lat: 0, lng: 0 },
    features: [],
    multimedia: [],
    flag: [],
    error: "",
  });

  // const settings = {
  //     dots: false,
  //     infinite: true,
  //     speed: 1500,
  //     autoplaySpeed: 3000,
  //     fade: true,
  //     autoplay: true,
  //     slidesToShow: 1,
  //     slidesToScroll: 1
  // };

  const txacheckindate = new URLSearchParams(window.location.search).get(
    "txacheckindate"
  );
  //console.log("txacheckindate", txacheckindate);

  useEffect(() => {
    const abortController = new AbortController();

    const fetchData = async () => {
      const res = await fetch(env.apiBase + "/api/atdw/getproduct/" + slug, {
        signal: abortController.signal,
      });
      const data = await res.json();

      if (data === "error") {
        setAtdwProduct((pre) => {
          let _new = { ...pre };
          _new.error = "error";
          return _new;
        });
        return;
      }

      const _preParkName = data.product.productName;
      setAtdwProduct((pre) => {
        let _new = { ...pre };
        _new.product = data.product;
        _new.serviceSlugs = data.servicesSlug;
        _new.atdwEx = data.atdwEx;
        for (var i in _new.product.addresses) {
          if (_new.product.addresses[i].attributeIdAddress === "PHYSICAL") {
            _new.geoCode.lat = Number(
              _new.product.addresses[i].geocodeGdaLatitude
            );
            _new.geoCode.lng = Number(
              _new.product.addresses[i].geocodeGdaLongitude
            );
            _new.address = _new.product.addresses[i].addressLine1;
            _new.postCode = _new.product.addresses[i].addressPostalCode;
            break;
          }
        }
        _new.product.communication.forEach((el) => {
          if (el.attributeIdCommunicationDescription === "Primary Phone") {
            _new.primaryPhone = el.communicationDetail;
          } else if (
            el.attributeIdCommunicationDescription === "Email Enquiries"
          ) {
            _new.email = el.communicationDetail;
          } else if (
            el.attributeIdCommunicationDescription === "URL Enquiries"
          ) {
            _new.siteUrl = el.communicationDetail;
          } else if (el.attributeIdCommunicationDescription === "Booking URL") {
            _new.bookingUrl = el.communicationDetail;
          }
        });

        // if(_new.product.externalSystems.length > 0){
        //     for(var nn in _new.product.externalSystems){
        //         if(_new.product.externalSystems[nn].externalSystemCode === "TXA_DEFAULT" ||
        //             (_new.product.externalSystems[nn].externalSystemCode === "TXA_MULTI" && env.MultiTxaCode.includes(_new.product.externalSystems[nn].externalSystemText)) ||
        //             (_new.product.externalSystems[nn].externalSystemCode === "TXA_CODE" && env.MultiTxaCode.includes(_new.product.externalSystems[nn].externalSystemText))) {
        //             let psn = _new.product.externalSystems[nn].externalSystemText;
        //             _new.bookingUrl = "https://www.au.v3travel.com/CABS4/Pages/Availability.aspx?exl_dn=SAParks_web&exl_psn=" + psn + "&exl_ad=2&exl_ch=0&exl_dte=" + ((txacheckindate && txacheckindate !== "") ? txacheckindate : '')
        //             //_new.bookingUrl = "https://www.au.v3travel.com/CABS4/Pages/Availability.aspx?exl_dn=SAParks_web&exl_psn=" + psn + "&exl_ad=2&exl_ch=0&exl_dte=";
        //             break;
        //         }
        //     }
        // }

        if (_new.atdwEx) {
          if (_new.atdwEx.txaCode !== null && _new.atdwEx.txaCode !== "") {
            _new.bookingUrl =
              "https://book.txa.com.au/v4/Pages/Availability.aspx?exl_dn=SAParks_web&exl_psn=" +
              _new.atdwEx.txaCode +
              "&exl_ad=2&exl_ch=0&exl_dte=&exl_lng=en-AU&exl_cur=AUD" +
              (txacheckindate && txacheckindate !== "" ? txacheckindate : "");
          }
        }

        _new.product.attributes.forEach((el) => {
          if (el.attributeTypeId === "ENTITY FAC") {
            _new.features.push(el);
          } else if (el.attributeTypeId === "MEMBERSHIP") {
            _new.flag.push(el);
          }
        });

        let va = [];
        //let checkImageType = false;
        let _tempDataId;
        for (var n = 0; n < _new.product.multimedia.length; n++) {
          if (
            _new.product.multimedia[n].attributeIdMultimediaContent ===
              "IMAGE" &&
            _new.product.multimedia[n].attributeIdSizeOrientation === "4X3" &&
            parseInt(_new.product.multimedia[n].width) >= 800
          ) {
            if (!_tempDataId) {
              _tempDataId = _new.product.multimedia[n].multimediaId;
            }

            if (_tempDataId === _new.product.multimedia[n].multimediaId) {
              va.push(_new.product.multimedia[n]);
            } else {
              _new.multimedia.push(va);
              va = [];
              va.push(_new.product.multimedia[n]);
              _tempDataId = _new.product.multimedia[n].multimediaId;
            }
          }

          if (n === _new.product.multimedia.length - 1 && va.length > 0) {
            _new.multimedia.push(va);
          }
        }
        return _new;
      });
      dispatch({ type: "SET_STATE", payload: { preParkName: _preParkName } });
    };

    fetchData();
    return () => {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, [slug, txacheckindate]);

  function GetDes(prop) {
    let _texts = prop.text.split("\n");
    return _texts.map((text, index) => <p key={index}>{text}</p>);
  }

  return (
    <Fragment>
      {atdwProduct.product ? (
        <div className='atdw-page'>
          <MetaTag data={getMetaTag(atdwProduct.product, slug)} />
          <AtdwPageBaner
            multimedia={atdwProduct.multimedia}
            primaryPhone={atdwProduct.primaryPhone}
            siteUrl={atdwProduct.siteUrl}
            bookingUrl={atdwProduct.bookingUrl}
            address={atdwProduct.product.addresses}
          />
          <section className='contentPanel'>
            <div className='container'>
              <div className='htmlPanel'>
                <h1 css={style.productName} className='productName'>
                  {" "}
                  {atdwProduct.product.productName}{" "}
                </h1>
                <h3 css={style.cityName} className='cityName'>
                  {" "}
                  {atdwProduct.product.cityName}{" "}
                </h3>
                <div css={style.atdwDes} className='productDes'>
                  <GetDes
                    text={atdwProduct.product.productDescription}
                  ></GetDes>
                </div>

                {atdwProduct.bookingUrl !== "" && (
                  <div
                    css={style.detailContainer}
                    className='detail__container'
                    style={{ marginTop: "2em" }}
                  >
                    <a
                      href={
                        atdwProduct.bookingUrl.includes("http")
                          ? atdwProduct.bookingUrl
                          : "https://" + atdwProduct.bookingUrl
                      }
                      className='button'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>
                        {" "}
                        <span> BOOK NOW </span>{" "}
                      </strong>
                    </a>
                  </div>
                )}

                <div css={style.detailContainer} className='detail__container'>
                  <AtdwAttributes features={atdwProduct.features} />
                </div>
                {atdwProduct.product.services.length > 0 && (
                  <Fragment>
                    <h3> Room Type : </h3>
                    <AtdwService
                      services={atdwProduct.product.services}
                      slugs={atdwProduct.serviceSlugs}
                      atdwName={atdwProduct.product.productName}
                      txacheckindate={txacheckindate}
                    ></AtdwService>
                  </Fragment>
                )}

                {atdwProduct.multimedia.length > 0 && (
                  <PhotoGallery item2={atdwProduct.multimedia}></PhotoGallery>
                )}
              </div>
              <div className='htmlPanel' style={{ textAlign: "center" }}>
                <AtdwLocation
                  productName={atdwProduct.product.productName}
                  geoCode={atdwProduct.geoCode}
                  address={atdwProduct.address}
                  postCode={atdwProduct.postCode}
                  primaryPhone={atdwProduct.primaryPhone}
                  email={atdwProduct.email}
                  siteUrl={atdwProduct.siteUrl}
                  flag={atdwProduct.flag}
                  bookingUrl={atdwProduct.bookingUrl}
                />
              </div>
            </div>
          </section>
        </div>
      ) : atdwProduct.error === "error" ? (
        <NotFound></NotFound>
      ) : (
        <Loading />
      )}
      <ViewPartial />
    </Fragment>
  );
}
