import React, { useState, useEffect } from 'react';
import { useRouter } from 'components';
//import { navigate, usePath, useLocationChange } from 'raviger';
import { SiteLink } from 'components';
import { NavBar } from './navBar';
import { FaAngleLeft } from 'react-icons/fa';
import { useHistory } from 'components';
import utils from 'utils';

export function TopHeader(props) {

  const { path } = useRouter();
  //const path = usePath();
  const [menuOpened, setMenuOpened] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(path);
  const [scrolled, setScrolled] = useState(false);

  //const [scrolled, setScrolled] = useState(false)
  // const history = useHistory();
  // console.log(history.length, history)

  const history = useHistory(path);

  const routeChanged = (path, history) => {
    //console.log(path, history);
    //console.log(window.location.hash)
    const hash = window.location.hash;
    //const search = window.location.search;
    //console.log("aaa", search);
    history.push(path)
    if (hash) { scrollTo(); }
  }

  useEffect(() => {
    scrollTo();
    if(window.scrollY >= 100){
      setScrolled(true);
    }
    let p = 0;
    window.addEventListener('scroll', function(){
      if(window.scrollY >= 100 ){
        setScrolled(true);
      }
      else{
        setScrolled(false);
      }

      let item = document.getElementById("atdwpagebanericonsbar");
      if(item){
        if(p === 0){
          p = item.offsetTop;
        }

        if(window.innerwidth <= 500){
          if((p - window.scrollY) <= 70){
            item.classList.add("dock");
          }
          else{
            item.classList.remove("dock");
          }
        }
        else{
          if((p - window.scrollY) <= 56){
            item.classList.add("dock");
          }
          else{
            item.classList.remove("dock");
          }
        }
      }
    });
  }, []);

  //const pageChanged = useCallback(path => routeChanged(path, history), [history])
  // useLocationChange(path => {
  //   routeChanged(path, history);
  //   setCurrentLocation(path);
  // })

  useEffect(() => {
    routeChanged(path, history);
    setCurrentLocation(path);
    // eslint-disable-next-line
  }, [path]);

  function scrollTo() {
    const hash = window.location.hash;
    if (hash) {
      //console.log(hash)
      setTimeout(() => {
        const anchor = utils.ui.findElement(hash);
        if (anchor)
          utils.ui.scrollTo(anchor, 500);
      }, 500)
    }
  }

  function toggleMenu(e) {
    //document.getElementsByTagName('html')[0].style.backgroundColor = 'red';
    if(!menuOpened){
      //document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      //document.getElementsByTagName('html')[0].style.overflow = 'hidden';
      //document.getElementsByTagName('html')[0].style.marginRight = '17px';
    }
    else{
      //document.getElementsByTagName('html')[0].style.overflow = '';
      //document.getElementsByTagName('html')[0].style.marginRight = '';
    }
    setMenuOpened(!menuOpened);
  }

  function onCloseMenu() {
    //document.getElementsByTagName('html')[0].style.overflow = '';
    //document.getElementsByTagName('html')[0].style.marginRight = '';
    setMenuOpened(false);
  }
  
  function goBack(e) {
    console.log("goBack");
    //e.preventDefault();
    history.getPrevious();
    //console.log(previousLink)
  }

  return (
    <>
      <div className={`menu-btn-mask ${menuOpened ? "on" : ""}`} onClick={toggleMenu}></div>
      <header className={`topNav ${scrolled ? "scrolled" : ""}`} id="top">
        {
          history.history.length > 1 && 
          <SiteLink className="topNav__back" title="Go Back" to={history.getPreviousPath()} onClick={goBack} >
            <FaAngleLeft /> BACK
          </SiteLink>
        } 
        {/* <div className="topNav__skewBar"></div> */}
        
        <SiteLink to="/" className="topNav__logo">
          {/* <img src="/assets/logos/logo.png" alt="logo" /> */}
          <img src= { scrolled ? "/assets/logos/logo-colour.png" : "/assets/logos/logo.png"} alt="logo" />
        </SiteLink >

        <button id="mobile-menu-icon" type="button" aria-label="Menu" className={`hamburger hamburger--collapse ${menuOpened ? 'is-active' : ''}`} onClick={toggleMenu}>
          <span className="hamburger-box">
            <span className="hamburger-inner">
            </span>
          </span>
        </button>

        <NavBar open={menuOpened} onCloseMenu={onCloseMenu} currentLocation={currentLocation}></NavBar>
      </header>
    </>
  );
}