/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
//import  utils from 'utils';
import { useState } from 'react';
import Select from 'react-select';

function dateFormat(date){
    var _date = date.getFullYear() + "-" +
        (('' + (date.getMonth() + 1)).length < 2 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" +
        (('' + (date.getDate())).length < 2 ? ("0" + (date.getDate())) : (date.getDate()));
    return _date;
}

function dateFormatPlus2(date){
    date.setDate(date.getDate() + 1);
    var _date = date.getFullYear() + "-" +
        (('' + (date.getMonth() + 1)).length < 2 ? ("0" + (date.getMonth() + 1)) : (date.getMonth() + 1)) + "-" +
        (('' + (date.getDate())).length < 2 ? ("0" + (date.getDate())) : (date.getDate()));
    return _date;
}

export function AtdwSearchOption(props){ 
    const atdwType = props.atdwType;
    const _petFriendly = (props.petFriendly && props.petFriendly === 'on') ? true : false;
    const hidePetFriendly = (props.petFriendly && props.petFriendly === 'hide') ? true : false;

    console.log("props.petFriendly", props.petFriendly);
    console.log("hidePetFriendly", hidePetFriendly);

    const numOfAdultsOption = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' }
    ];

    const numOfKidsOption = [
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' }
    ];

    const [searchOptionValue, setSearchOptionValue] = useState({
        checkIn : '',
        checkOut : '',
        numOfAdults : numOfAdultsOption[1],
        numOfKids : numOfKidsOption[0],
        subType : [],
        town : '',
        name : '',
        petFriendly : _petFriendly
    });

    const today = new Date();
    const checkInMinDate = dateFormat(today);
    const [checkOutMinDate, setCheckOutMinDate] = useState(dateFormatPlus2(today));
    //const [triggerPetCheck, setTriggerPetCheck] = useState(0);
    const typeOptions = setTypeOptions();
    //console.log("1111", typeOptions.items);
    function setTypeOptions(){
        if(atdwType === 'ACCOMM'){
            return {title : 'Accommodation Type',
                        //bookingAble : false,
                        bookingAble : true,
                        items : [
                    // { value: 'APARTMENT', label: 'Apartments' },
                    // { value: 'BACKPACKER', label: 'Backpackers and Hostels' },
                    // { value: 'BEDBREAKFA', label: 'Bed and Breakfasts' },
                    // { value: 'CABCOTTAGE', label: 'Cabins and Cottages' },
                    // { value: 'VANCAMP', label: 'Caravan and Camping' },
                    // { value: 'FARMSTAY', label: 'Farm Stays' },
                    // { value: 'HOLHOUSE', label: 'Holiday Houses' },
                    // { value: 'HOTEL', label: 'Hotels' },
                    // { value: 'MOTEL', label: 'Motels' },
                    // { value: 'CABCOTTAGE', label: 'Cabins and Cottages' },
                    // { value: 'RESORT', label: 'Resorts' },
                    // { value: 'RETREAT', label: 'Retreats' }
            ]};
        }
        else if(atdwType === 'ATTRACTION'){
            return {title : 'Attraction Type', bookingAble : false,
                     items : [
                         { value: 'SHOPPING', label: 'Shopping and Markets' },
                         { value: 'SPARETREAT', label: 'Spas and Retreats' },
                        ]
                    }
        }
        else if(atdwType === 'RESTAURANT'){
            return {title : 'Food & Drink Type', bookingAble : false,
                     items : [
                         { value: 'BAR', label: 'Bar' },
                         { value: 'TAKEAWAY', label: 'Takeaway Available' },
                        ]
                    }
        }
        else if(atdwType === 'EVENT'){
            return {title : 'Event Type', bookingAble : false,
                     items : [
                         { value: 'EVTBUS', label: 'Business Event' },
                         { value: 'EVTFOOD', label: 'Food and Wine' },
                        ]
                    }
        }
        else if(atdwType === 'HIRE'){
            return {title : 'Hire Type', bookingAble : false,
                     items : [
                         { value: 'BICYCLE', label: 'Bicycles' },
                         { value: 'BOAT', label: 'Boats' },
                        ]
                    }
        }
        else if(atdwType === 'GENSERVICE'){
            return {title : 'Service Type', bookingAble : false,
                     items : [
                         { value: 'CARPARK', label: 'Carpark' },
                         { value: 'FAMLYFREND', label: 'Family Friendly' },
                        ]
                    }
        }
        else if(atdwType === 'TOUR'){
            return {title : 'Tour Type', bookingAble : false,
                     items : [
                         { value: 'AOTOURS', label: 'Adventure and Outdoors Tours' },
                         { value: 'AHBTOURS', label: 'Air, Helicopter and Balloon Tours' },
                        ]
                    }
        }
        else
        {
            return {title : '', bookingAble : false, items : []}
        }
    }

    function onSubmit(event){
        event.preventDefault();
        const value = searchOptionValue;
        props.onGetSearchOption(value);
    }
    
    // const onSubmit = useCallback((event) => {
    //     console.log("absdfdfds");
    //     event.preventDefault();
    //     setCount(count + 1);
    // }, [count2]);

    function handChange(el){
        const elName = el.target.name;
        const elValue = el.target.value;
        if(elName === "checkIn"){ 
            const date = new Date(elValue);
            setCheckOutMinDate(dateFormatPlus2(date));
            setSearchOptionValue({...searchOptionValue, [elName] : elValue, "checkOut" : dateFormat(date)});
        }
        else if(elName === "petFriendly"){
            setSearchOptionValue({...searchOptionValue, petFriendly : el.target.checked});
            //setTriggerPetCheck(triggerPetCheck + 1);
        }
        else{
            setSearchOptionValue({...searchOptionValue, [elName] : elValue});
        }
    }

    // useEffect(() => {

    //     if(triggerPetCheck === 0){
    //         return;
    //     }

    //     const value = searchOptionValue;
    //     props.onGetSearchOption(value);

    //     // eslint-disable-next-line
    // }, [triggerPetCheck]);

    return(
        <form css = {style.atdwSearchOption} onSubmit = {onSubmit}>
            <div className="row">
                {
                    typeOptions.items.length > 0 ? (
                        <div className="col-12">
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect2">{typeOptions.title}</label>
                        <Select placeholder ="select" name="subType" options = {typeOptions.items} isMulti closeMenuOnSelect={false} noOptionsMessage={() => "No Item"} onChange={(val)=>{
                            handChange({target : {name : "subType", value : val}});
                        }}></Select>
                        </div>
                        </div>
                    ) : null
                }

                <div className="col-sm-4 col-12">
                    {
                        typeOptions.bookingAble ? (
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Check in</label>
                                <button css={style.date_reset_btn} className="btn btn-primary" onClick={((e)=>{
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setSearchOptionValue({...searchOptionValue, 'checkIn' : '', 'checkOut' : ''});
                                })}>Reset Date</button>
                                <input type="date" min={checkInMinDate} onChange={handChange} value={searchOptionValue.checkIn} name="checkIn" className="form-control form-control-sm" placeholder="" autoComplete="off"/>
                            </div>
                        ) : null
                    }
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput">Town</label>
                        <input type="text" onChange={handChange} name="town" value={searchOptionValue.town} className="form-control form-control-sm" placeholder="" autoComplete="off"/>
                    </div>
                </div>

                <div className="col-sm-4 col-12"> 
                    {
                        typeOptions.bookingAble ? (
                            <div className="form-group">
                                <label htmlFor="formGroupExampleInput">Check out</label>
                                <input type="date" min={checkOutMinDate} onChange={handChange} value={searchOptionValue.checkOut} name="checkOut" className="form-control form-control-sm" placeholder="" autoComplete="off"/>
                            </div>
                        ) : null
                    } 
                    <div className="form-group">
                        <label htmlFor="formGroupExampleInput">Search by Name</label>
                        <input type="text" className="form-control form-control-sm" placeholder="" onChange={handChange} name="name" value={searchOptionValue.name} autoComplete="off"/>
                    </div>
                </div>

                <div className="col-sm-4 col-12">
                    
                    {/* {
                        typeOptions.bookingAble ? (
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="formGroupExampleInput">Adults</label>
                                    <Select options = {numOfAdultsOption} value={searchOptionValue.numOfAdults} name="numOfAdults" onChange={(val)=>{
                                        handChange({target : {name : "numOfAdults", value : val}});
                                    }}></Select>
                                </div>
                                <div className="form-group col">
                                    <label htmlFor="formGroupExampleInput">Kids</label>
                                        <Select options = {numOfKidsOption} value={searchOptionValue.numOfKids} name="numOfKids" onChange={(val)=>{
                                            handChange({target : {name : "numOfKids", value : val}});
                                        }}></Select> 
                                </div>
                            </div>
                        ) : null
                    } */}

                    <div className="form-group">
                        <label css={style.dumyLabel}>Search button</label>
                        <button css={style.search_btn} style={{width:'100%'}} className="btn btn-primary" type="submit">
                            SEARCH
                        </button>
                    </div>

                    {
                        hidePetFriendly === false && 
                        <div className="form-group">
                            <div className="form-check" css={style.pet}>
                                <input className="form-check-input" type="checkbox" checked={searchOptionValue.petFriendly} id="petFriendly" name='petFriendly' onChange={handChange} />
                                <label className="form-check-label" htmlFor="petFriendly">
                                    Pet Friendly
                                </label>
                            </div>
                        </div>
                    }

                </div>

            </div>
        </form>
    );
}