/** @jsx jsx */
import { jsx } from '@emotion/core';
import utils from 'utils';
import style from './style';
import { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import env from 'env';
//import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";

function Portal({children}){
    //const mount = document.getElementById("portal-root");
    //const el = document.createElement("div");
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
      return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function AtdwMap(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdwMap', item.cssClass || item.anchorName || '');
    const [mapSate, setMapSate ] = useState({
        currentFilterType :'ACCOMM',
        currentFilterArea :'all',
        trigger:0,
        fetchAble:true,
        data : []
        //accomm : [],
        //attraction : []
    });

    const el = useRef(null);
    const history = useHistory();
    const [openPopup, setOpenPopup] = useState(false);
    const [popupData, setPopupData] = useState();
    
    useEffect(()=>{
        if(mapSate.fetchAble === false){
            return;
        }
        const fetchData = async () =>{
            const res = await fetch(env.apiBase + "/api/atdw/getproductsall/" + mapSate.currentFilterArea);
            const data = await res.json();
            setMapSate(pre=>{
                let _new = {...pre};
                _new.fetchAble = false;
            
                let items = [];
                _new.data = data.products;
                items = _new.data;
                
                let center = {lat: -35.0855058, lng: 135.5129228};
                let zoom = 6;

                if(mapSate.currentFilterArea === "Adelaide"){
                    center = { lat: -34.9262741, lng: 138.5971424 };
                    zoom = 9;
                }
                else if(mapSate.currentFilterArea === "Adelaide Hills"){
                    center = { lat: -35.042298, lng: 138.8284648 };
                    zoom = 9;
                }

                var map = new window.google.maps.Map(el.current, {
                    center: center,
                    zoom: zoom
                });

                if(items.length > 0){
                    items.forEach(_el => {
                        //console.log("_", _el);
                        // let icon = "default.png";
                        // if(_el.additionalData.membership.includes("TTP")){
                        //     icon = "maker_ttp.png";
                        // }
                        // else if(_el.additionalData.membership.includes("BIG4")){
                        //     icon = "maker_big4.png";
                        // }
                        // else if(_el.additionalData.membership.includes("KUIPARK")){
                        //     icon = "maker_kuipark.png";
                        // }
    
                        let geoCode = {
                            lat : Number(_el.additionalData.googlemap.geocodeGdaLatitude),
                            lng : Number(_el.additionalData.googlemap.geocodeGdaLongitude)
                        };
                        var marker = new window.google.maps.Marker({
                            position: {lat: geoCode.lat, lng: geoCode.lng},
                            map: map,
                            title:_el.productName
                            //icon : '/assets/icons/' + icon
                        });
                        var infowindow = new window.google.maps.InfoWindow({
                            //content : _el.shortDesc
                            content : '<div class="info__window">' +
                                        '<div class="info__title">' + _el.productName + '</div>' + 
                                        '<div class="info__town">' + _el.town + '</div>' + 
                                        '<div class="info__town">' + _el.address + ' ' +  _el.town + '</div>' + 
                                        '<p class="info__desc">' + _el.shortDesc + '</p>' + 
                                    '</div>'
                        //     contet : "<div class="agm-pop-up">
                        //     <div class="title"> {{ model.productName }} </div>
                        //     <div class="address"> {{ model.address + ' '+ model.town  }} </div>
                        //     <p class="desc"> {{ model.shortDesc }} </p>
                        //   </div>";
                        });
                        // marker.addListener('click', function(){
                        //     infowindow.open(map, marker);
                        // });
    
                        var isMobile = false;
                        //var isMobile = true;
                        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
                            isMobile = true;
                        }
    
                        marker.addListener('mouseover', function(){
                            if(!isMobile) {
                                infowindow.open(map, marker);
                            }
                        });
                        marker.addListener('mouseout', function(){ 
                            if(!isMobile) {
                                infowindow.close();
                            }
                        });
                        marker.addListener('click', function(){
                            if(isMobile) {
                                setPopupData({
                                    productName : _el.productName,
                                    address : _el.address,
                                    town : _el.town,
                                    desc : _el.shortDesc,
                                    slug : _el.slug
                                });
                                setOpenPopup(true);   
                            }
                            else{
                                history.push('/atdwproduct/' + _el.slug);
                            }
                        });
                    });
                } 
                return _new;
            });
        }
        fetchData();
    }, [mapSate, history]);

    const switchMap = useCallback((value)=>{
        setMapSate(pre=>{
            let _new = {...pre};
            _new.fetchAble = true;
            //_new.currentFilterType = value;
            _new.currentFilterArea = value;
            return _new;
        });
    }, []);

    const Filters = (() => {
        return(
            <div css={style.filters} className="atdw__map__filter">
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Adelaide' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Adelaide'){
                        switchMap('Adelaide');
                    }
                }}>Adelaide</button>
                
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Adelaide Hills' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Adelaide Hills'){
                        switchMap('Adelaide Hills');
                    }
                }}>Adelaide Hills</button>
                
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Barossa' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Barossa'){
                        switchMap('Barossa');
                    }
                }}>Barossa</button>
                
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Clare Valley' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Clare Valley'){
                        switchMap('Clare Valley');
                    }
                }}>Clare Valley</button>

                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Eyre Peninsula' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Eyre Peninsula'){
                        switchMap('Eyre Peninsula');
                    }
                }}>Eyre Peninsula</button>

                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Fleurieu Peninsula' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Fleurieu Peninsula'){
                        switchMap('Fleurieu Peninsula');
                    }
                }}>Fleurieu Peninsula</button>

                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Flinders Ranges' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Flinders Ranges'){
                        switchMap('Flinders Ranges');
                    }
                }}>Flinders Ranges</button>

                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Kangaroo Island' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Kangaroo Island'){
                        switchMap('Kangaroo Island');
                    }
                }}>Kangaroo Island</button>

                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Limestone Coast' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Limestone Coast'){
                        switchMap('Limestone Coast');
                    }
                }}>Limestone Coast</button>
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Murraylands' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Murraylands'){
                        switchMap('Murraylands');
                    }
                }}>Murraylands</button>
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Riverland' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Riverland'){
                        switchMap('Riverland');
                    }
                }}>Riverland</button>
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Yorke Peninsula' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Yorke Peninsula'){
                        switchMap('Yorke Peninsula');
                    }
                }}>Yorke Peninsula</button>
                <button className={`btn btn-primary ${mapSate.currentFilterArea === 'Outback' ? 'selected' : ''}`} onClick={()=>{
                    if(mapSate.currentFilterArea !== 'Outback'){
                        switchMap('Outback');
                    }
                }}>Outback</button>
            </div>
        )
    });
    
    return(
        <div css={style.atdwMap} className = {cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div style = {{height : "450px"}}>
                <div ref = {el} style = {{height:"100%"}}></div>
            </div>
            <Filters />
            <Portal>
                {
                    openPopup && 
                    <Fragment>
                        <div css={style.popupBackground}></div>
                        <div css={style.container}>
                            <div css={style.popupTitle}> { popupData.productName } </div>
                            <div css={style.popupTown}> { popupData.town } </div>
                            <div css={style.popupTown}> { popupData.address + ' ' +  popupData.town } </div>
                            <p css={style.popupDesc}> { popupData.desc } </p>
                            <div style={{textAlign:'center'}}>
                                    <button className="btn btn-primary" onClick={()=>{
                                        history.push('/atdwproduct/' + popupData.slug);
                                    }}>Go</button>
                                    <button className="btn btn-primary" style={{marginLeft:'1rem'}} onClick={()=>{
                                        setOpenPopup(false);
                                        setPopupData(null);
                                    }} >Close</button>
                            </div>
                        </div>
                    </Fragment>
                }
            </Portal>
        </div>
    )
}