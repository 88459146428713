//import { css, keyframes  } from '@emotion/core'
//import { variables, mq } from 'cssInJs'

import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    atdwSearchOption : css({
         '& .form-group label' : {
             marginBottom : '0px'
         },
         '.form-control:focus' : {
            boxShadow : 'none'
         }
    }),
    search_btn : css(mq({
        //marginTop: ['auto', '17px']
        marginTop: ['auto']
    })),
    date_reset_btn : css({
        marginLeft: '1rem',
        fontSize: '0.8rem',
        padding: '0px 10px',
        //marginBottom: '5px',
        marginTop: '-10px',
        backgroundColor: 'gray',
        borderColor: 'gray',
        borderRadius: '10px'
    }),
    dumyLabel : css({
        color: 'transparent'
    }),
    pet : css({
        marginTop:'2.5rem',
        paddingLeft:'2rem',
        '.form-check-input' : css({
            width: '20px',
            height: '20px',
            marginTop:'0.3rem',
            marginLeft:'-2rem'
        }),
        // '.form-check-label' : css({
        //     fontSize:'1.5rem',
        //     fontWeight: '700'
        // })
    })
}