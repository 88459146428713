/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { useState, useEffect } from 'react';
import style from './style';
import { AtdwTile } from '../tile/view';

export function AtdwSearchResult(props){

    const atdwDatas = props.atdwData || [];
    const fromCalled = props.fromCalled || "";
    
    return(
        <div className="atdwResult">
            <div css={style.atdwTiles} className="atdwTiles"> 
                { atdwDatas.map((item, index) => <AtdwTile fromCalled = {fromCalled} {...item} key = {index}></AtdwTile> )}
                {/* { atdwDatas.map((item, index) => {
                    return (<AtdwTile {...item} key = {index}></AtdwTile>)
                })} */}
            </div>
        </div>
    )
}