/** @jsx jsx */
import { jsx } from "@emotion/core";
import { useState, useEffect, Fragment, useRef } from "react";
import env from "env";
import { useRouter } from "components";
import Slider from "react-slick";
import style from "./style";
import { FaCircle } from "react-icons/fa";
import { ViewPartial } from "cms/pages/partials/viewPartial";
import { MdDirections, MdCall, MdWeb } from "react-icons/md";
import { useStore } from "../../../store";

function ServicePageBanner(props) {
  //console.log("props11111111", props);
  const multimedia = props.multimedia;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    fade: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const primaryPhone = props.primaryPhone || "";
  const siteUrl = props.siteUrl || "";
  const bookingUrl = props.bookingUrl || "";

  return (
    <section>
      <Slider {...settings}>
        {multimedia.length > 0 ? (
          multimedia.map((item, index) => {
            return (
              <div css={style.tileSize} className='tileSize' key={index}>
                <picture>
                  {item.map((_item, _index) => {
                    return (
                      <source
                        key={_index}
                        crossOrigin='anonymous'
                        srcSet={_item.serverPath}
                        media={_item.width <= 800 ? "(min-width:600px)" : null}
                      />
                    );
                  })}
                  <img src='/assets/logos/logo2.png' alt='image2' />
                </picture>
              </div>
            );
          })
        ) : (
          <div css={style.tileSize} className='tileSize'>
            {/* <img src ="/assets/logos/logo2.png" alt="image2" /> */}
          </div>
        )}
      </Slider>
      <div
        css={style.banerIconsBar}
        className='atdw__page__banerIconsBar'
        id='atdwpagebanericonsbar'
      >
        {primaryPhone === "" ? null : (
          <a href={"tel:" + primaryPhone} className='bar__item'>
            <MdCall />
            <div className='icon__text'>PHONE</div>
          </a>
        )}
        <div
          className='bar__item'
          onClick={() => {
            document
              .getElementById("atdwPageLocation")
              .scrollIntoView({ behavior: "smooth", block: "center" });
          }}
        >
          <MdDirections />
          <div className='icon__text'>LOCATION</div>
        </div>

        {siteUrl === "" ? null : (
          <a
            href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
            className='bar__item'
            target='_blank'
            rel='noopener noreferrer'
          >
            <MdWeb />
            <div className='icon__text'>WEBSITE</div>
          </a>
        )}
        {bookingUrl && bookingUrl !== "" && (
          <a
            href={bookingUrl}
            css={style.booking__button}
            className='bar__item booking__button'
            target='_blank'
            rel='noopener noreferrer'
          >
            <img
              css={style.booking_button_image}
              src='/assets/book-now-icon.png'
              alt='booking_icon'
            />
            <div className='icon__text'>BOOKINGS</div>
          </a>
        )}
      </div>
    </section>
  );
}

function ServiceFeatures(props) {
  const features = props.features;
  return (
    <Fragment>
      <h3> Features </h3>
      <div className='row' style={{ paddingLeft: "1rem" }}>
        {features.map((item, index) => {
          return (
            <div className='col-6 col-sm-4' key={index}>
              <FaCircle
                style={{
                  height: "0.2em",
                  width: "0.2em",
                  verticalAlign: "middle",
                }}
              />
              <span> {item.attributeIdDescription} </span>
            </div>
          );
        })}
      </div>
    </Fragment>
  );
}

function AtdwLocation(props) {
  const productName = props.productName;
  const geoCode = props.geoCode;
  const address = props.address;
  const postCode = props.postCode;
  //const _address = (address + '+SA+' + postCode).replace(/\s+/g, '+');
  const primaryPhone = props.primaryPhone || "";
  const email = props.email || "";
  const siteUrl = props.siteUrl || "";
  const flag = props.flag;
  const ele = useRef(null);
  const bookingUrl = props.bookingUrl || "";

  useEffect(() => {
    var map, marker;
    map = new window.google.maps.Map(ele.current, {
      center: {
        lat: Number(geoCode.geocodeGdaLatitude),
        lng: Number(geoCode.geocodeGdaLongitude),
      },
      zoom: 15,
    });
    marker = new window.google.maps.Marker({
      position: {
        lat: Number(geoCode.geocodeGdaLatitude),
        lng: Number(geoCode.geocodeGdaLongitude),
      },
      map: map,
      title: productName,
    });
    console.log(marker);
  }, [productName, geoCode]);

  const getFlag = () => {
    let result = [];
    for (var i in flag) {
      if (flag[i].attributeId === "BIG4") {
        result.push(
          <li key={i}>
            <img src='/assets/icons/big4.jpg' alt='image2' />
          </li>
        );
      }
    }
    return result.length > 0 ? (
      <div css={style.flag}>
        <ul>{result}</ul>
      </div>
    ) : null;
  };

  return (
    <div style={{ textAlign: "center" }}>
      <h3 css={style.contact_text}>{"Contact " + productName}</h3>
      <h4> {address + " SA " + postCode}</h4>
      <div>
        {/* {'GPS: South ' + geoCode.lat + ' East ' + geoCode.lng} */}
        <span css={style.gps_text}>
          {" "}
          {"GPS: South " + geoCode.geocodeGdaLatitude}{" "}
        </span>
        <span css={style.gps_text} style={{ marginLeft: "0.5rem" }}>
          {" "}
          {"East " + geoCode.geocodeGdaLongitude}{" "}
        </span>
      </div>
      <div css={style.info}>
        <ul>
          <li>
            <a href={"tel:" + primaryPhone}>{primaryPhone}</a>
          </li>
          <li>
            <a href={"mailto:" + email}>Email Us</a>
          </li>
          <li>
            <a
              href={siteUrl.includes("http") ? siteUrl : "https://" + siteUrl}
              target='_blank'
              rel='noopener noreferrer'
            >
              {"Visit The " + productName + " Website"}
            </a>
          </li>
        </ul>
      </div>
      {getFlag()}
      {bookingUrl === "" ? null : (
        <div style={{ marginTop: "1rem", marginBottom: "1.3rem" }}>
          <a
            href={
              bookingUrl.includes("http") ? bookingUrl : "https://" + bookingUrl
            }
            className='button'
            target='_blank'
            rel='noopener noreferrer'
          >
            <strong>
              {" "}
              <span> BOOK NOW </span>{" "}
            </strong>
          </a>
        </div>
      )}

      <div
        id='atdwPageLocation'
        style={{ height: "450px", marginTop: "1rem", marginBottom: "1rem" }}
      >
        <div ref={ele} style={{ height: "100%" }}></div>
        {/* <iframe
                    width="100%"
                    height="450"
                    frameBorder="0" style={{border:'0'}}
                    title={ productName }
                    src={'https://www.google.com/maps/embed/v1/place?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY&q=' + _address} allowFullScreen>
                </iframe> */}
      </div>
    </div>
  );
}

export function ServicePage() {
  const { dispatch } = useStore();
  const slug = useRouter().query.slug;
  const [atdwService, setAtdwService] = useState({
    service: null,
    product: null,
    multimedia: [],
    features: [],
    flag: [],
    bookingUrl: "",
  });

  const txacheckindate = new URLSearchParams(window.location.search).get(
    "txacheckindate"
  );
  console.log("txacheckindate", txacheckindate);
  console.log(
    "new URLSearchParams(window.location.search)",
    new URLSearchParams(window.location.search)
  );

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetch(env.apiBase + "/api/atdw/getservice/" + slug);
      const data = await res.json();

      if (data !== "error") {
        setAtdwService((pre) => {
          let _new = { ...pre };
          _new.service = data.service;
          _new.product = data.product;

          let va = [];
          for (var i = 0; i < _new.service.multimedia.length; i++) {
            if (i === 0) {
              va.push(_new.service.multimedia[i]);
            }

            if (
              _new.service.multimedia[i].attributeIdMultimediaContent ===
                "IMAGE" &&
              _new.service.multimedia[i].attributeIdSizeOrientation === "4X3" &&
              parseInt(_new.service.multimedia[i].width) >= 800
            ) {
              va.push(_new.service.multimedia[i]);
            }

            if (
              _new.service.multimedia[i].attributeIdMultimediaContent ===
                "IMAGE" &&
              _new.service.multimedia[i].attributeIdSizeOrientation === "4X3" &&
              parseInt(_new.service.multimedia[i].width) >= 800
            ) {
              va.push(_new.service.multimedia[i]);
            }

            if (i === _new.service.multimedia.length - 1 && va.length > 0) {
              let _images = va;
              va = [];
              _new.multimedia.push(_images);
            }
          }

          _new.service.attributes.forEach((el) => {
            if (el.attributeTypeId === "SVC FAC") {
              _new.features.push(el);
            }
          });

          _new.product.additionalData.attributes.forEach((el) => {
            if (el.attributeTypeId === "MEMBERSHIP") {
              _new.flag.push(el);
            }
          });

          if (
            _new.product.additionalData.bookingUrl &&
            _new.product.additionalData.bookingUrl !== ""
          ) {
            _new.bookingUrl = _new.product.additionalData.bookingUrl.includes(
              "http"
            )
              ? _new.product.additionalData.bookingUrl
              : "https://" + _new.product.additionalData.bookingUrl;
            if (_new.bookingUrl.includes("//book.txa.com.au/v4/Pages/")) {
              _new.bookingUrl =
                _new.bookingUrl +
                "&exl_lng=en-AU&exl_cur=AUD" +
                (txacheckindate && txacheckindate !== "" ? txacheckindate : "");
            }
          }

          if (_new.product.txaCode && _new.product.txaCode !== "") {
            _new.bookingUrl =
              "https://book.txa.com.au/v4/Pages/Availability.aspx?exl_dn=SAParks_web&exl_psn=" +
              _new.product.txaCode +
              "&exl_ad=2&exl_ch=0&exl_dte=&exl_lng=en-AU&exl_cur=AUD" +
              (txacheckindate && txacheckindate !== "" ? txacheckindate : "");
          }

          dispatch({
            type: "SET_STATE",
            payload: { preParkName: _new.product.productName },
          });

          return _new;
        });
      }
    };
    fetchData();

    // eslint-disable-next-line
  }, [slug]);
  console.log(
    "%c [ atdwService ]-265",
    "font-size:13px; background:#C0CF67; color:#495300;",
    atdwService
  );

  return (
    <Fragment>
      {atdwService.service ? (
        <div className='service-page'>
          <ServicePageBanner
            multimedia={atdwService.multimedia}
            primaryPhone={atdwService.product.phone}
            siteUrl={atdwService.product.additionalData.siteUrl}
            bookingUrl={atdwService.bookingUrl}
          />
          <section className='contentPanel'>
            <div className='container'>
              <div className='htmlPanel'>
                <h1 css={style.productName} className='service_productName'>
                  {" "}
                  {atdwService.product.productName}{" "}
                </h1>
                <h3 css={style.serviceName} className='service_serviceName'>
                  {" "}
                  {atdwService.service.serviceName}{" "}
                </h3>
                <p css={style.serviceDes}>
                  {atdwService.service.serviceDescription}
                </p>

                {atdwService.bookingUrl !== "" && (
                  // <div style={{marginTop:'1rem'}}>
                  //     <a href={atdwService.product.additionalData.bookingUrl.includes("http") ? atdwService.product.additionalData.bookingUrl : 'https://' + atdwService.product.additionalData.bookingUrl } className="button" target="_blank" rel="noopener noreferrer">
                  //         <strong> <span> BOOK NOW </span> </strong>
                  //     </a>
                  // </div>
                  <div style={{ marginTop: "1rem" }}>
                    <a
                      href={atdwService.bookingUrl}
                      className='button'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <strong>
                        {" "}
                        <span> BOOK NOW </span>{" "}
                      </strong>
                    </a>
                  </div>
                )}

                <ServiceFeatures features={atdwService.features} />
                <AtdwLocation
                  productName={atdwService.product.productName}
                  address={atdwService.product.address}
                  postCode={atdwService.product.additionalData.postcode}
                  geoCode={atdwService.product.additionalData.googlemap}
                  primaryPhone={atdwService.product.phone}
                  email={atdwService.product.additionalData.email}
                  siteUrl={atdwService.product.additionalData.siteUrl}
                  flag={atdwService.flag}
                  bookingUrl={atdwService.product.additionalData.bookingUrl}
                />
              </div>
            </div>
          </section>
        </div>
      ) : null}
      <ViewPartial />
    </Fragment>
  );
}
