import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    tileSize : css(mq({
        //border : '1px solid red',
        position : 'relative',
        height : ['300px', '450px'],
        backgroundImage:'url("/assets/logos/logo.png")',
        backgroundPosition:'center',
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
        'img' : {
            //border : '1px solid red'
            position : 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            '@media(max-width:600px)' : {
                height : '100%',
                width: 'auto'
            }
        }
    })),

    banerIconsBar : css({
        textAlign : 'center',
        backgroundColor : '#fe3a43',
        padding : '0.3rem 0',
        display : 'flex',
        flexDirection : 'row',
        flexWrap : 'wrap',
        justifyContent : 'center',
        'svg' : {
            width : '32px',
            height : '32px',
            color : 'white'
        },
        // '.bar__item' : {
        //     margin : '0px 20px',
        //     cursor : 'pointer',
        //     '&:hover' : {
        //         textDecoration : 'unset'
        //     }
        // },
        '.bar__item' : mq({
            margin : ['0px 15px', null, '0px 20px'],
            cursor : 'pointer',
            '&:hover' : {
            textDecoration : 'unset'
            }
        }),
        '.bar__item.booking__button' :mq({
            margin : ['0px 10px', null, '0px 15px'],
        }),
        '.icon__text' : {
            fontSize : '.6rem',
            color : '#fff'
        },
        '@media(max-width:600px)' : {
            marginTop:'-7px'
        }
        // '&.dock' : mq({
        //     position:'fixed',
        //     top:['50px', '44px', '56px'],
        //     width: '100%',
        //     zIndex:'9'
        // })
    }),

    cityName : css(mq({
        //textAlign : 'center',
        margin : '0px',
        fontSize : ['1.6rem', '1.8rem'],
        marginTop: '0.5rem',
        marginBottom: '0.5rem'
    })),

    productName : css(mq({
        //textAlign : 'center',
        margin : '0px'
        //fontSize : ['2rem']
    })),

    detailContainer : css({
        // '.Collapsible' : {
        //     //backgroundColor : 'grey'
        // },
        // '.Collapsible__contentInner' : {
        //     padding: '10px',
        //     //border: '1px solid red',
        //     borderTop: '0',
        //     fontSize : '14px',
        //     'p' : {
        //         marginBottom: '10px',
        //         fontSize: '14px',
        //         lineHeight: '20px',
        //         '&:last-child' : {
        //             marginBottom: '0'
        //         }
        //     }
        // },
        // '.Collapsible__trigger' : {
        //     display: 'block',
        //     fontWeight: '400',
        //     textDecoration: 'none',
        //     position: 'relative',
        //     //border: '1px solid white',
        //     borderBottom: '1px solid #252525',
        //     padding: '10px',
        //     background: 'transparent',
        //     color: '#252525',
        //     cursor : 'pointer',
        //     '&:after' : {
        //         content: '""',
        //         position: 'absolute',
        //         right: '10px',
        //         top: '10px',
        //         display: 'block',
        //         transition: 'transform 300ms',
        //         backgroundImage : 'url("/assets/icons/arrow.svg")',
        //         padding : '15px',
        //         backgroundSize: 'contain',
        //         backgroundPosition: 'center'
        //     },
        //     '&.is-open' : {
        //         '&:after' : {
        //             transform: 'rotateZ(180deg)'
        //         }
        //     },
        //     '&.is-disabled' : {
        //         opacity: '0.5',
        //         backgroundColor: 'grey'
        //     },
        //     'CustomTriggerCSS' : {
        //         backgroundColor: 'lightcoral',
        //         transition: 'background-color 200ms ease'
        //     },
        //     '.CustomTriggerCSS--open' : {
        //         backgroundColor: 'darkslateblue'
        //     },
        //     '.Collapsible__Custom-sibling' : {
        //         padding: '5px',
        //         fontSize: '12px',
        //         backgroundColor: '#CBB700',
        //         color: 'black'
        //     }
        // }
        marginTop: '2rem'
    }),
    atdwLoaction__click : css({
        position : 'absolute',
        width : '100%',
        height:'48px',
        zIndex: '99999',
        cursor: 'pointer'
    }),
    atdwDes : css({
        'p' : {
            marginBottom : '1rem'
        },
        'p:last-child' : {
            marginBottom : '0px'
        }
    }),
    serviceTiles : css({
        display: 'flex',
        alignItems: 'stretch',
        flexWrap: 'wrap',
        //justifyContent: 'space-between'
        justifyContent: 'start'
    }),

    info : css({
        'ul, li' : {
            listStyle: 'none',
            margin: '0',
            padding: '0',
            display: 'inline-block',
            'li' : {
                margin: '0 .7rem 0 0',
                padding: '0 .7rem 0 0',
                listStyle: 'none',
                borderRight: '1px solid black',
                lineHeight: '1',
                '&:last-of-type' : {
                    borderRight: '0'
                }
            }
        },
        marginBottom:'0.5rem'
    }),

    flag : css({
        marginTop:'1rem',
        marginBottom:'0.5rem',
        'ul, li' : {
            listStyle: 'none',
            margin: '0',
            padding: '0',
            display: 'inline-block',
            'li' : {
                margin: '0 .7rem 0 0',
                padding: '0 .7rem 0 0',
                listStyle: 'none',
                //borderRight: '1px solid black',
                lineHeight: '1',
                // '&:last-of-type' : {
                //     borderRight: '0'
                // }
                '&:last-child' : {
                    marginRight: '0px',
                    paddingRight:'0px'
                }
            }
        }
    }),
    contact_text : css(mq({
        fontSize: ['1.6rem', '1.8rem']
    })),
    gps_text : css(mq({
        display: ['inline-block', 'inline']
    })),

    booking__button : {
        svg : {
            transition: 'all 0.5s ease-in-out'
        },
        '&:hover' : {
            svg:{
                transform:'translateY(-3px)'
            },
            img : {
                transform:'scale(1.2)'
            }
        }
    },
    booking_button_image : {
        height: '32px',
        transition: 'all 0.5s ease-in-out',
        //margin : ['0px 10px', null, '0px 15px'],
    }
}