/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Fragment } from 'react';
import { ForgotPassword } from '../forgotPassword/view';
import { ResetPassword } from '../resetPassword/view';
import { ViewPartial } from 'cms/pages/partials/viewPartial';
import { Register } from '../register/view';

export function SiteAuthLayout(props){
    
    return(
        <Fragment>
        <div className="banner-item bannerSize--standard" style={{backgroundImage:'url(/sites/saparks/media/banners/riverbend-2-blue.jpg)'}}></div>
        <div>
            <Switch>
                <Route path="/auth/forgot-password">
                    <ForgotPassword></ForgotPassword>
                </Route>
                <Route path="/auth/reset-password">
                    <ResetPassword></ResetPassword>
                </Route>
                <Route path="/auth/register">
                    <Register></Register>
                </Route>
                <Route path="/*">
                    <Redirect to="/auth/forgot-password" />
                </Route>
            </Switch>
        </div>
        <ViewPartial />
        </Fragment>
    )
}