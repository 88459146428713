import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    
    serviceTile : css(mq({
        flexGrow: '1',
        flexShrink: '0',
        
        // flexBasis: ['260px', '260px', '334px', '299px', '267px'],
        // maxWidth: ['100%', '100%', '334px', '299px', '267px'],
        flexBasis: ['100%', '48%', null, '32%', '32.3%'],
        maxWidth: ['100%', '49%', null, '33%', '32.3%'],

        margin: '10px 5px',
        backgroundColor : '#ebebeb',
        '& a' : {
            textDecoration : 'none',
            color : '#252525'
        },
        'hr' : {
            height: '2px',
            border: '0px',
            backgroundImage: 'linear-gradient(90deg,#fe3a43,transparent)'
        },
        '&:hover' : {
            '.viewMore' : {
                display: 'block'
            },
            '.bg::after' : {
                content: '""',
                backgroundColor: 'rgba(0,0,0,0.3)',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '50%',
                transform: 'translateY(-50%)',
            }
        }
    })),
    bgContainer :css({
        paddingTop: '60%',
        position:'relative'
    }),
    bg : css({
        //paddingTop: '60%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height:'100%',
        position:'absolute',
        top:'0px'
        //position:'relative'
    }),
    town : css({
        padding : '0rem 0.5rem',
        backgroundColor : '#fe3a43',
        fontWeight : '700',
        color:'white',
        lineHeight: '1.1',
        paddingBottom:'3px',
        paddingTop:'2px'
    }),
    title : css({
        lineHeight:'1',
        fontWeight:'700',
        fontSize:'0.9rem',
        marginTop: '0.1rem'
    }),
    descContainer : css({
        padding : '0.2rem 0.5rem',
        paddingBottom : '0.5em',
        '& hr' :{
            margin : '0.3rem 0rem'
        }
    }),
    desc : css({
        fontSize : '0.8rem'
    }),
    shotDesc : css({
        fontSize : '0.8rem',
        marginTop: '0.2rem',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '5',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        minHeight: '6rem'
    }),
    viewMore : css({
        border: '3px solid white',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform:'translate(-50%, -50%)',
        color:'white',
        padding:'0.5rem 1rem 7px',
        fontWeight:'700',
        display:'none',
        zIndex:'9',
        width: '150px'
    })
}