// import { fb, validators, formModelHelper } from 'form';

// export function getContactModel(data) {

//   const enquiryTypeList = [
//     { name: 'Enquiry Type*', value: "" },
//     { name: 'Park or Booking Enquiry', value: "parkBookingEnquiry" },
//     { name: 'Order Caravan & Camping Guide', value: "orderCaravanCampingGuide" },
//     { name: 'General Enquiry', value: "generalEnquiry" },
//   ];

//   const model = fb.group({

//     name: [data.name || '', [validators.Required()], { label: 'Name', type: 'text' }],
//     phone: [data.phone || '', [validators.Required()], { label: 'Phone', type: 'text' }],
//     email: [data.email || '', [validators.Required(), validators.Email()], { label: 'Email', type: 'email' }],
//     enquiry: [data.enquiry || '', [validators.Required()], { label: 'Your Message', type: 'textarea' }],
//     enquiryType : [data.enquiryType || '', [validators.Required()], { label: '', type: 'select', options : enquiryTypeList, onBeforeUpdateValue : (control, formModel) => {

//       const _parkName = formModelHelper.findControl("parkName", formModel);
//       const _streetAddress = formModelHelper.findControl("streetAddress", formModel);
//       const _postCode = formModelHelper.findControl("postCode", formModel);
//       const _citySuburb = formModelHelper.findControl("citySuburb", formModel);
//       const _enquiry = formModelHelper.findControl("enquiry", formModel);

//       _parkName.validators = [];
//       _streetAddress.validators = [];
//       _postCode.validators = [];
//       _citySuburb.validators = [];

//       _enquiry.label = "Your Message";

//       if(control.value === 'parkBookingEnquiry'){
//         _parkName.validators.push(validators.Required());
//       }
//       else if(control.value === 'orderCaravanCampingGuide'){
//         _streetAddress.validators.push(validators.Required());
//         _postCode.validators.push(validators.Required());
//         _citySuburb.validators.push(validators.Required());
//         _enquiry.label = "Your Message/Delivery Instructions";
//         _enquiry.validators = [];
//       }

//     } }],
//     parkName: [data.preParkName || '', [], { label: 'Park Name', type: 'text' }],
//     streetAddress: [data.streetAddress || '', [], { label: 'Street Address', type: 'text' }],
//     postCode: [data.postCode || '', [], { label: 'Post Code', type: 'text' }],
//     citySuburb: [data.citySuburb || '', [], { label: 'City/Suburb', type: 'text' }],
//   });
//   return model;
// }

import { fb, validators } from "form";

export function getContactModel(data) {
  const enquiryTypeList = [
    { name: "Enquiry Type*", value: "" },
    { name: "Park or Booking Enquiry", value: "parkBookingEnquiry" },
    {
      name: "Order Caravan & Camping Guide",
      value: "orderCaravanCampingGuide",
    },
    { name: "General Enquiry", value: "generalEnquiry" },
  ];

  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Full Name", type: "text" },
    ],
    //fName: [data.fName || '', [validators.Required()], { label: 'First Name', type: 'text' }],
    //lName: [data.lName || '', [validators.Required()], { label: 'Last Name', type: 'text' }],
    phone: [
      data.phone || "",
      [validators.Required()],
      { label: "Phone", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
    enquiry: [
      data.enquiry || "",
      data.enquiryType && data.enquiryType === "orderCaravanCampingGuide"
        ? []
        : [validators.Required()],
      {
        label:
          data.enquiryType && data.enquiryType === "orderCaravanCampingGuide"
            ? "Your Message/Delivery Instructions"
            : "Your Message",
        type: "textarea",
      },
    ],
    //enquiry: [data.enquiry || '', [validators.Required()], { label: (data.enquiryType && data.enquiryType === "orderCaravanCampingGuide") ? 'Your Message/Delivery Instructions' : 'Your Message' , type: 'textarea' }],
    enquiryType: [
      data.enquiryType || "",
      [validators.Required()],
      { label: "", type: "select", options: enquiryTypeList },
    ],
    parkName: [
      data.parkName || "",
      data.enquiryType && data.enquiryType === "parkBookingEnquiry"
        ? [validators.Required()]
        : [],
      { label: "Park Name", type: "text" },
    ],
    streetAddress: [
      data.streetAddress || "",
      data.enquiryType && data.enquiryType === "orderCaravanCampingGuide"
        ? [validators.Required()]
        : [],
      { label: "Street Address", type: "text" },
    ],
    postCode: [
      data.postCode || "",
      data.enquiryType && data.enquiryType === "orderCaravanCampingGuide"
        ? [validators.Required()]
        : [],
      { label: "Post Code", type: "text" },
    ],
    citySuburb: [
      data.citySuburb || "",
      data.enquiryType && data.enquiryType === "orderCaravanCampingGuide"
        ? [validators.Required()]
        : [],
      { label: "City/Suburb", type: "text" },
    ],
    mailchimp: [
      "",
      [],
      {
        label:
          "Yes! Sign me up to your E-Newsletter to get all the Latest News, Events & Offers.",
        type: "checkbox",
      },
    ],
  });
  return model;
}
