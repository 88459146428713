/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import Slider from "react-slick";
import utils from '../../../utils';
import cmsUtils from '../../../cms/utils/cmsUtils';
import { SiteLink } from '../../../components';
import { useMemo } from 'react';

export function SliderAdPanel(props) {
  const item = props.item;
  //console.log('item', item);
  //const defaultDesH = 220;
  const subItems = item.items || [];
  //console.log(subItems.length);

  const shuffledSubItems = useMemo(() => {
    const shuffled = JSON.parse(JSON.stringify(subItems));
    var currentIndex = shuffled.length, temporaryValue, randomIndex;
    while(0 !== currentIndex){
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = shuffled[currentIndex];
      shuffled[currentIndex] = shuffled[randomIndex];
      shuffled[randomIndex] = temporaryValue;
    }

    //console.log("1", shuffled);
    return shuffled;

    // eslint-disable-next-line
  }, []);
  
  const cmsOption = props.cmsOption || {};
  const cssClass = utils.classNames('cms_item', 'sliderAdPanel', item.cssClass || item.anchorName || '');

  function sendEventToGa(item){
    window.dataLayer.push(Object.assign({ event: 'AdEvent' }, {
      'eventCategory' :  item.eCategory,
      'eventAction' :  item.eAction,
      'eventLabel' :  item.eLabel,
      'eventValue' :  item.eValue,
    }));
  }
  
  const sliderAdItems = shuffledSubItems.map((subItem, index) => {

    const imageUrl = cmsUtils.payload(subItem, 'ImageUrl');
    const imageUrl2 = cmsUtils.payload(subItem, 'ImageUrl2');
    const bottomText = cmsUtils.payload(subItem, 'BottomText');
    const linkUrl = cmsUtils.payload(subItem, 'LinkUrl');
    const currentImageUrl = window.innerWidth <= 600 ? imageUrl2 : imageUrl;
    const eTracking = cmsUtils.payload(subItem, 'Event_tracking');

    return (
      <div key={index} css={style.sliderImageWrapper}>
        {
          (linkUrl && linkUrl !== '') ? 
        <SiteLink to={linkUrl}>
          <div css={style.sliderImageWrapper2} onClick={(eTracking && eTracking === 'on') ? () => {

            const data = { 
              eCategory : cmsUtils.payload(subItem, 'Event_category'),
              eAction : cmsUtils.payload(subItem, 'Event_action'),
              eLabel : cmsUtils.payload(subItem, 'Event_label'),
              eValue : cmsUtils.payload(subItem, 'Event_value')
            }
            sendEventToGa(data);
          } : null}> 
            <img css={style.sliderImage} key={subItem.itemId} alt={'sliderimage-' + index} src={currentImageUrl} />
            <div>{bottomText}</div>
          </div>
        </SiteLink> : 
        
        <div css={style.sliderImageWrapper2}>
          <img css={style.sliderImage} key={subItem.itemId} alt={'sliderimage-' + index} src={currentImageUrl} />
          <div>{bottomText}</div>
        </div>
        }
      </div>
    )
    }
  );

  var numOfSlides = (maxNum) => subItems.length >= maxNum ? maxNum : subItems.length

  var settings = {
    dots: false,
    infinite: true,
    speed: 1500,
    autoplaySpeed: 3000,
    ////fade: true,
    autoplay: true,
    slidesToShow: numOfSlides(1),
    //slidesToShow: 3,
    slidesToScroll: numOfSlides(1),
    // responsive: [
    //   { breakpoint: 1024, settings: { slidesToShow: numOfSlides(5), slidesToScroll: numOfSlides(1) } },
    //   { breakpoint: 992, settings: { slidesToShow: numOfSlides(4), slidesToScroll: numOfSlides(1) } },
    //   { breakpoint: 768, settings: { slidesToShow: numOfSlides(3), slidesToScroll: numOfSlides(1) } },
    //   { breakpoint: 576, settings: { slidesToShow: numOfSlides(2), slidesToScroll: numOfSlides(1)} }
    // ]
  };

  return (
    <div css={style.sliderAdPanel} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      <Slider {...settings}>
        {sliderAdItems}
      </Slider>
    </div>
  );
}