import React, { useLayoutEffect, useRef } from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { useRouter } from 'components';
import { handleElementLink } from 'components';
import env from '../../../env';
import { useStore } from "store/storeProvider";

export function Html(props) {
  const ver = env.ver;
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  const { history } = useRouter();
  const { dispatch } = useStore();
  //console.log('item', item, cmsOption);

  //const content = cmsUtils.payload(item, 'Content');
  const year = (new Date()).getFullYear();
  let content = cmsOption.isCmsEdit ?
    cmsUtils.payload(item, 'Content') :
    cmsUtils.payload(item, 'Content')
    .replace(/__year__/g, year)
    .replace("__version__", "<a href = '/app' class = 'app_version'> PWA " + ver + "</a>");

  const cssClass = utils.classNames('cms_item', 'htmlPanel', cmsOption.isCmsEdit && 'html', item.cssClass || item.anchorName || '');
  //let itemElt = (<div dangerouslySetInnerHTML={{ __html: content }}></div>);

  function handleClick(e) {

    let target = "";
    let hrefType = "";

    if (e.target.closest('a')) {
      target = e.target.closest('a').getAttribute('target');
      hrefType = e.target.closest('a').getAttribute('href');
    }

    //const processed = handleElementLink(e.target);
    const processed = (target === "_blank" || hrefType.includes('tel:') ? handleElementLink(e.target, null) : handleElementLink(e.target));
    if (processed.handled) {
      e.preventDefault();
      e.stopPropagation();
    } else if (processed.linkInfo && processed.linkInfo.type === 'internal') {      
      e.preventDefault();
      e.stopPropagation();
      history.push(processed.linkInfo.url)
    }
    // else {
    //   e.preventDefault();
    //   e.stopPropagation();
    // }

    if(window.location.href.includes('/guide') && hrefType === '#contact'){
      e.preventDefault();
      e.stopPropagation();
      dispatch({type:'SET_STATE', payload : {enquiryType : "orderCaravanCampingGuide" }});
    }

  }

  const htmlBlock = useRef(null);
  // function iframeSizer() {
  //   //console.log('iframesizer')
  //   let iframesContainer = htmlBlock.current.querySelectorAll('iframe.youtube, .ytvideo iframe');
  //   //console.log('iframesizer', iframesContainer)
  //   if (iframesContainer && iframesContainer.length) {
  //     iframesContainer.forEach(element => {
  //       let height = (element.parentElement.offsetWidth * 9) / 16
  //       //console.log(height, element.parentElement.offsetWidth)
  //       element.style.height = height + 'px';
  //     });
  //   }
  // }


  useLayoutEffect(() => {
    const buttons = htmlBlock.current.querySelectorAll('.button');
    for (let i = 0; buttons && i < buttons.length; i++) {
      restructureButton(buttons[i])
    }

    // iframeSizer();
    // window.addEventListener('resize', iframeSizer)
    // return (): void => {
    //   window.removeEventListener('resize', iframeSizer)
    // }

  }, [])

  return (
    <div ref={htmlBlock} className={cssClass} data-cms-html="true"
      data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
      dangerouslySetInnerHTML={{ __html: content }} onClick={(e) => handleClick(e)}></div>
  )
}

function restructureButton(el) {
  if (!el.querySelector('span')) {
    const span = document.createElement('span');
    span.innerHTML = el.innerHTML;
    el.innerHTML = '';
    el.appendChild(span);
  }
}