/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { SiteLink, useRouter } from 'components';
import { useState, useEffect } from 'react';
import env from 'env';
import { Redirect } from 'react-router-dom';
import { Loading } from 'components';

export function ResetPassword(props){
    const [password, setPassword] = useState("");
    const [buttonTrigger, setButtonTrigger] = useState(0);
    const { query } = useRouter();
    const [msg, setMsg] = useState();
    const [redirect, setRedirect] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    const token = query.u;

    function onSubmit(e) {
        e.preventDefault();   
        e.stopPropagation();
        setPageLoading(true);
        setButtonTrigger(buttonTrigger + 1);
    }

    useEffect(() => {
        if(buttonTrigger === 0){
            return;
        }
        const fetchData = async () => {
            const res = await fetch(env.apiBase + '/api/profile/resetpassword', {
                method : 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({password: password, token: token})
            });
            const data = await res.json();
            if(data.errors.length > 0){
                setMsg(data.errors[0].errorMessage);
            }
            else{
                setTimeout(() => {
                    setRedirect(true);    
                }, 1000);
            }
            setPageLoading(false);
        }
        fetchData();
        setButtonTrigger(0);
    }, [buttonTrigger, token, password]);

    return( 
        pageLoading ? <Loading /> :  
            <form css={style.formContainer} autoComplete="off" onSubmit = {onSubmit}>
            <h1 style={{textAlign:'center'}}>Reset Password</h1>
            <div className="form-group">
                <label htmlFor="username"> New Password </label>
                <input className="form-control" required onChange={(e)=>{
                    setPassword(e.target.value);
                }} value={password} />
            </div>

            {
                msg ? <div css={style.textContainer}>
                    {msg}
                </div> : null
            }
            
            <div className="form-group" style={{textAlign:'center'}}>
                <button css={style.buttonStyle} type="submit" className="btn btn-primary">
                    Reset Password
                </button>
            </div>
            <SiteLink to = "/test">
                Go back to Log in?
            </SiteLink>
            {
                redirect ? <Redirect to="/members-login"></Redirect> : null
            }
        </form>
    )
}