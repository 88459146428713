import React from "react";
import { BannerPanel } from "cms/items/bannerPanel/view";
import { BannerItem } from "cms/items/bannerItem/view";
import { Panel } from "cms/items/panel/view";
import { Html } from "cms/items/html/view";
import { TestimonialPanel } from "cms/items/testimonialPanel/view";
import { TestimonialItem } from "cms/items/testimonialItem/view";
import { ImagePanel } from "cms/items/imagePanel/view";
import { PageTiles } from "cms/items/pageTiles/view";
import { SingleBanner } from "cms/items/singleBanner/view";
import { ContactForm } from "cms/items/contactForm/view";
import { YoutubeBannerPanel } from "cms/items/youtubeBannerPanel/view";
import { Code } from "cms/items/code/view";
import { BlogPosts } from "cms/items/blogPosts/view";
import { BlogPostTitle } from "cms/items/blogPostTitle/view";
import { MultiColumnPanel } from "components/multiColumnPanel/view";
import { NonAtdwPageInfoBar } from "components/NonAtdwPageInfoBar/view";
import { Login } from "components/login/view";
import { ChangeDetails } from "components/changeDetails/view";
import { PhotoGallery } from "components/photoGallery/view";
import { SliderAdPanel } from "components/SliderAd/index";
import { SignupForm } from "cms/items/signupForm/view/signupForm";
import {
  AtdwSearch,
  AtdwSearchV2,
  AtdwMap,
  MixedListings,
} from "site/atdw/index";

export function Item(props) {
  const item = props.item;
  //const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};
  //console.log('item', item, subItems, cmsOption);
  let itemElt = <></>;
  if (item.itemTypeCode === "Panel") {
    itemElt = <Panel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Html") {
    itemElt = <Html item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "BannerPanel") {
    itemElt = <BannerPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "BannerItem") {
    itemElt = <BannerItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "TestimonialPanel") {
    itemElt = <TestimonialPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "TestimonialItem") {
    itemElt = <TestimonialItem item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "ImagePanel") {
    itemElt = <ImagePanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "PageTiles") {
    itemElt = <PageTiles item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "SingleBanner") {
    itemElt = <SingleBanner item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "ContactForm") {
    itemElt = <ContactForm item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "YoutubeBannerPanel") {
    itemElt = <YoutubeBannerPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Code") {
    itemElt = <Code item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Blog-Posts") {
    itemElt = <BlogPosts item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "Blog-PostTitle") {
    itemElt = <BlogPostTitle item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "MultiColumnPanel") {
    itemElt = <MultiColumnPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "AtdwSearch") {
    itemElt = <AtdwSearch item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "AtdwSearchV2") {
    itemElt = <AtdwSearchV2 item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "AtdwMap") {
    itemElt = <AtdwMap item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "NonAtdwPageInfoBar") {
    itemElt = <NonAtdwPageInfoBar item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "MemberLogin") {
    itemElt = <Login item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "ChangeDetails") {
    itemElt = <ChangeDetails item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "MixedListings") {
    itemElt = <MixedListings item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "PhotoGallery") {
    itemElt = <PhotoGallery item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "SliderAdPanel2") {
    itemElt = <SliderAdPanel item={item} cmsOption={cmsOption} />;
  } else if (item.itemTypeCode === "SignupForm") {
    itemElt = <SignupForm item={item} cmsOption={cmsOption} />;
  } else {
    itemElt = <div>{item.itemTypeCode}</div>;
  }
  // const subItemsElts = subItems.map((subItem, index) =>
  //   <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  // );
  return (
    <>
      {itemElt}
      {/* {subItemsElts} */}
    </>
  );
}
