/** @jsx jsx */
import { jsx } from '@emotion/core';
import cmsUtils from 'cms/utils/cmsUtils';
import utils from 'utils';
import { SiteLink } from 'components';
import style from './style';

export function PageTiles(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};
  
  const tileType = cmsUtils.payload(item, 'TileType') || 'main'; //none_atdw
  //console.log('item', item);
  const pages = getPages(cmsUtils.payload(item, 'PageObjects'))
  //console.log(pages);
  const pageTileElts = pages.map((page, index) =>

    tileType === 'none_atdw' ? <TileNoneAtdw page={page} key={index} disableLink={cmsOption.isCmsEdit} idx = {index}></TileNoneAtdw> :
    <Tile page={page} key={index} disableLink={cmsOption.isCmsEdit}></Tile>
  );

  const cssClass = utils.classNames('cms_item', 'pageTiles tiles', tileType, item.cssClass || item.anchorName || '');

  return (
    <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
      {pageTileElts}
    </div>
  );
}

function getPages(pagesSerialized) {
  if (pagesSerialized) {
    return JSON.parse(pagesSerialized);
  }
  return [];
}

function Tile(props) {
  const page = props.page;
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div className="pageTile tile">
      <SiteLink className="pageTile__content tile__content" to={disableLink ? '' : page.pageUrl}>
        <div className="tile__bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        <div className="pageTile_details tile__details">
          <hr className="pageTile__hr"></hr>
          <div className="pageTile__title">
            {page.pageTitle}
          </div>
          {/* <div className="pageTile_desc">
            {page.tileDesc || page.description}
          </div> */}
        </div>
      </SiteLink>
    </div>
  )
}

function TileNoneAtdw(props) {
  const page = props.page;
  //console.log("page", page);
  const imageUrl = utils.site.resourcePath(page.imageUrl);
  const disableLink = props.disableLink;
  return (
    <div css={style.atdwTile} className="atdwTile">
      <SiteLink to={disableLink ? '' : page.pageUrl}>
        <div css={style.bg} className="bg" style={{ backgroundImage: utils.css.bgUrlStyle(imageUrl) }}></div>
        <div css={style.town} className="town">
          {page.tileButtonText || "SA PARK"}
        </div>
        <div css={style.descContainer}  className="desc-container">
          <div css={style.title} className="title">
            {page.pageTitle}
          </div>
          <hr />
          <div css={style.shotDesc} className="shot-desc">
            {page.tileDesc || page.description}
          </div>
        </div>
      </SiteLink>
    </div>
  )
}