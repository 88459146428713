/** @jsx jsx */
import { jsx } from '@emotion/core';
//import style from './style';
import utils from 'utils';
import { AtdwSearchOptionV2 } from '../searchOptionV2/view';
import { AtdwSearchResult } from '../searchResult/view';
import style from './style';
import cmsUtils from 'cms/utils/cmsUtils';
import { useState, useEffect, useCallback, useContext, Fragment } from 'react';
import env from 'env';
import { AtdwContext } from '../index';
import { createPortal } from "react-dom";
import { MdClose } from "react-icons/md";

function Portal({ children }) {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

// function AtdwSearchTitle(props) {
//     const type = props.type;
//     //console.log("type",type);
//     const title = { sTitle: '', title: '' };
//     if (type === 'ACCOMM') {
//         title.sTitle = "SEARCH & BOOK";
//         title.title = "ACCOMMODATION";
//     }
//     else if (type === 'ATTRACTION') {
//         title.sTitle = "SEARCH";
//         title.title = "ATTRACTIONS";
//     }
//     else if (type === 'DESTINFO') {
//         title.sTitle = "SEARCH";
//         title.title = "TOWN";
//     }
//     else if (type === 'RESTAURANT') {
//         title.sTitle = "SEARCH";
//         title.title = "RESTAURANT";
//     }
//     else if (type === 'EVENT') {
//         title.sTitle = "SEARCH";
//         title.title = "EVENT";
//     }
//     else if (type === 'INFO') {
//         title.sTitle = "SEARCH";
//         title.title = "INFO";
//     }
//     else if (type === 'TOUR') {
//         title.sTitle = "SEARCH";
//         title.title = "TOUR";
//     }
//     else if (type === 'GENSERVICE') {
//         title.sTitle = "SEARCH";
//         title.title = "GENSERVICE";
//     }
//     else if (type === 'JOURNEY') {
//         title.sTitle = "SEARCH";
//         title.title = "JOURNEY";
//     }

//     return (
//         <h3 className="atdw__title"><strong>{title.sTitle}</strong> {title.title}</h3>
//     )
// }

export function AtdwSearchV2(props) {

    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'atdwSearchV2', item.cssClass || item.anchorName || '');
    const bgColor = cmsUtils.payload(item, 'BgColor');
    const imageUrl = cmsUtils.payload(item, 'ImageUrl');
    const backgroundTile = cmsUtils.payload(item, 'BackgroundTile');

    const petFriendly = cmsUtils.payload(item, 'PetFriendly') || 'off';
    const tilesSize = 12;

    const atdwArea = cmsUtils.payload(item, 'ProductArea') || "all";
    const customCategories = cmsUtils.payload(item, 'CustomCategories') || "";
    const filter = cmsUtils.payload(item, 'Filter') || "all";
    //const filter = cmsUtils.payload(item, 'Filter') || "category_1";

    let bgUrl = null;
    if (imageUrl)
        bgUrl = utils.site.resourcePath(imageUrl);
    else if (backgroundTile)
        bgUrl = utils.site.resourcePath(`/dist/transparent-textures/textures/${backgroundTile}`);

    const [atdwState, setAtdwState] = useState({
        atdwType: 'ACCOMM',
        atdwArea: atdwArea,
        index: 1,
        atdwDataFromDB: [],
        reserveAtdwData: [],
        shuffledAtdwData: [],
        filteredAtdwData: [],
        atdwData: [],
        trigger: 0,
        context: useContext(AtdwContext),
        //atdwFilters: null
    });

    //const [atdwSearchText, setAtdwSearchText] = useState("Searching for ATDW");
    const [atdwSearchText, setAtdwSearchText] = useState(" ");
    const [openPopup, setOpenPopup] = useState(false);
    const [filterData, setFilterData] = useState();

    //TXA
    const [trigger, setTrigger] = useState(0);
    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [numOfAdults, setNumOfAdults] = useState('');
    const [numOfKids, setNumOfKids] = useState('');

    //checkIn, checkOut, numOfAdults, numOfKids,
    function searechAtdwFilter({ petFriendly, town, name, subType, atdwDataItem, customCategories }) {

        //const _petFriendly = petFriendly || false;
        //const _customCategories = customCategories || '';

        const _town = town || '';
        const _name = name || '';
        const _subType = subType || [];

        const _filteredData = atdwDataItem.filter((data) => {
            let returnResult = false;
            //1
            if (_subType.length > 0) {
                //checkFilterDo = true
                for (var i in data.additionalData.PRODUCTTYPES) {
                    returnResult = false;
                    if (subType.toString().includes(data.additionalData.PRODUCTTYPES[i].productTypeId)) {
                        returnResult = true;
                    }
                    else {
                        returnResult = false;
                        break;
                    }
                }
            }
            else {
                returnResult = true;
            }

            //2
            if (returnResult === true && (_town !== "" && _town !== null)) {
                //checkFilterDo = true
                returnResult = false;
                if (data.town.toLowerCase().includes(_town.toLowerCase())) {
                    returnResult = true;
                }
            }

            //3
            if (returnResult === true && (_name !== "" && _name !== null)) {
                //checkFilterDo = true
                returnResult = false;
                if (data.productName.toLowerCase().includes(_name.toLowerCase())) {
                    returnResult = true;
                }
            }

            //4 pet
            // if (returnResult === true && (_petFriendly === true)) {
            //     //checkFilterDo = true
            //     returnResult = false;
            //     const _checkPetFriendly = data.checkPetFriendly || false;
            //     if (_checkPetFriendly === true) {
            //         returnResult = true;
            //     }
            // }

            //5 customCategories
            // if (returnResult === true && _customCategories !== null && _customCategories !== "") {
            //     returnResult = false;
            //     if (data.customCategories && data.customCategories !== '') {
            //         const _customCategoryArray = _customCategories.split(",");
            //         for (let i in _customCategoryArray) {
            //             returnResult = false;
            //             if (data.customCategories.toLowerCase().includes(_customCategoryArray[i].toLowerCase())) {
            //                 returnResult = true;
            //                 break;
            //             }
            //         }
            //     }
            // }
            return returnResult;
        });

        return _filteredData;
    }

    function categoryFilter({ atdwDataItem }) {
        const _filteredData = atdwDataItem.filter((data) => {
            let returnResult = false;
            let bypass = false;
            if (filterData.filteredData.length > 0) {
                if (data.productFilters && data.productFilters.length > 0) {
                    for (let i in filterData.filteredData) {
                        if (filterData.filteredData[i].categoryCode !== 'category_area') {
                            returnResult = false;
                            const _filterCheckItem = data.productFilters.find(el => el.filterId === filterData.filteredData[i].filterId);
                            if (!_filterCheckItem || _filterCheckItem === null) {
                                returnResult = false;
                                break;
                            }
                            else {
                                returnResult = true;
                            }
                        }
                        else {
                            // if(bypass === false && filterData.filteredData[i].filterName === data.area){
                            //     returnResult = true;
                            //     bypass = true;
                            // }
                            if (filterData.filteredData[i].filterName === data.area) {
                                returnResult = true;
                                bypass = true;
                            }
                            else {
                                if (bypass === false) {
                                    returnResult = false;
                                }
                            }
                        }

                        // returnResult = false;
                        // const _filterCheckItem = data.productFilters.find(el => el.filterId === filterData.filteredData[i].filterId);
                        // console.log("_filterCheckItem", _filterCheckItem);

                        // if (!_filterCheckItem || _filterCheckItem === null) {
                        //     returnResult = false;
                        //     break;
                        // }
                        // else {
                        //     returnResult = true;
                        // }
                    }

                }
            }
            else {
                returnResult = true;
            }

            if (filterData.petFriendly === true && returnResult === true) {
                if (data.checkPetFriendly !== true) {
                    returnResult = false;
                }
            }
            return returnResult;
        });

        return _filteredData;
    }

    function onGetSearchOptionFun(value) {

        const _checkIn = value.checkIn ? value.checkIn : '';
        const _checkOut = value.checkOut ? value.checkOut : '';
        const _numOfAdults = value.numOfAdults.value ? value.numOfAdults.value : '2';
        const _numOfKids = value.numOfKids.value ? value.numOfKids.value : '0';
        //const _petFriendly = value.petFriendly ? value.petFriendly : false;

        const subType = [];
        for (var i in value.subType) {
            subType.push(value.subType[i].value);
        }
        const town = value.town.trim();
        const name = value.name.trim();

        //let checkFilterDo = false

        const _filteredData = searechAtdwFilter({
            //petFriendly: _petFriendly,
            town: town,
            name: name,
            subType: subType,
            atdwDataItem: atdwState.shuffledAtdwData,
            //customCategories: customCategories
            //checkFilterDo : checkFilterDo
        });

        // const _data = [];

        // if (_filteredData.length > 0) {
        //     for (let x = 0; x < tilesSize; x++) {
        //         _data.push(_filteredData[x]);
        //         if (x === (_filteredData.length - 1)) {
        //             break;
        //         }
        //     }
        // }

        setAtdwState(pre => {
            let _new = { ...pre };
            _new.context.setupAtdwTxaCheckInDate(null);
            _new.reserveAtdwData = _filteredData;

            if (_checkIn !== '') {
                _new.atdwData = [];
                setCheckIn(_checkIn);
                setCheckOut(_checkOut);
                setNumOfAdults(_numOfAdults);
                setNumOfKids(_numOfKids);
                setTrigger(trigger + 1);
                //setAtdwSearchText("Searching for ATDW");
                setAtdwSearchText(" ");
                _new.context.setupAtdwTxaCheckInDate(_checkIn);
            }
            else {

                const filteredDataResult = categoryFilter({ atdwDataItem: _new.reserveAtdwData });
                const _data = [];
                if (filteredDataResult.length > 0) {
                    for (let x = 0; x < tilesSize; x++) {
                        _data.push(filteredDataResult[x]);
                        if (x === (filteredDataResult.length - 1)) {
                            break;
                        }
                    }
                }
                _new.atdwData = _data;
                _new.filteredAtdwData = filteredDataResult;
                _new.index = 1;
                _new.context.setupAtdwDataHistory({
                    atdwDataFromDB: _new.atdwDataFromDB,
                    reserveAtdwData: _new.reserveAtdwData,
                    atdwData: _new.atdwData,
                    index: _new.index
                });
                if (_data.length === 0) {
                    setAtdwSearchText("Sorry, there are no available results.");
                }
                else {
                    setAtdwSearchText("");
                }
            }
            return _new;
        });
    }

    function openPopupFun() {
        setOpenPopup(true);
        document.body.style.overflow = "hidden";
    }

    function closePopupFun() {
        setOpenPopup(false);
        document.body.style.overflow = "unset";
    }

    function changeCurrentFilter(_filter, _filterData) {
        filterData.currentFilter = _filter;
        filterData.currentFilterData = JSON.parse(JSON.stringify(_filterData));
        setFilterData({ ...filterData });
    }

    function changeFilter(_filter) {
        // filterData.currentFilter = _filter;
        // filterData.currentFilterData = JSON.parse(JSON.stringify(_filterData));

        switch (_filter) {
            case 'select__filter':
                filterData.selectedFilter.categoryOpenCheck = !filterData.selectedFilter.categoryOpenCheck;
                break;
            case 'category_1':
                filterData.filters.category_1.categoryOpenCheck = !filterData.filters.category_1.categoryOpenCheck;
                break;
            case 'category_2':
                filterData.filters.category_2.categoryOpenCheck = !filterData.filters.category_2.categoryOpenCheck;
                break;
            case 'category_3':
                filterData.filters.category_3.categoryOpenCheck = !filterData.filters.category_3.categoryOpenCheck;
                break;
            case 'category_4':
                filterData.filters.category_4.categoryOpenCheck = !filterData.filters.category_4.categoryOpenCheck;
                break;
            case 'category_5':
                filterData.filters.category_5.categoryOpenCheck = !filterData.filters.category_5.categoryOpenCheck;
                break;
            case 'category_6':
                filterData.filters.category_6.categoryOpenCheck = !filterData.filters.category_6.categoryOpenCheck;
                break;
            case 'category_area':
                filterData.filters.category_area.categoryOpenCheck = !filterData.filters.category_area.categoryOpenCheck;
                break;
            default:
                break;
        }

        setFilterData({ ...filterData });
    }

    function handChange(el) {
        const elName = el.target.name;
        const elValue = el.target.value;
        if (elName === 'filter__select') {
            switch (elValue) {
                case 'category_1':
                    changeCurrentFilter('category_1', filterData.filters.category_1);
                    break;
                case 'category_2':
                    changeCurrentFilter('category_2', filterData.filters.category_2);
                    break;
                case 'category_3':
                    changeCurrentFilter('category_3', filterData.filters.category_3);
                    break;
                case 'category_4':
                    changeCurrentFilter('category_4', filterData.filters.category_4);
                    break;
                case 'category_5':
                    changeCurrentFilter('category_5', filterData.filters.category_5);
                    break;
                default:
                    break;
            }
        }
    }

    function handChangeForFilter(el) {

        const value = el.value;
        const categoryCode = el.categoryCode;
        const filterId = el.filterId;

        switch (categoryCode) {
            case 'category_1':
                let _filter1 = filterData.filters.category_1.filters.find(el => el.filterId === filterId);
                _filter1.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_1;

                let _count1 = 0;
                filterData.filters.category_1.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count1++;
                    }
                });
                filterData.filters.category_1.categoryCheckedNum = _count1;
                break;
            case 'category_2':
                let _filter2 = filterData.filters.category_2.filters.find(el => el.filterId === filterId);
                _filter2.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_2;

                let _count2 = 0;
                filterData.filters.category_2.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count2++;
                    }
                });
                filterData.filters.category_2.categoryCheckedNum = _count2;

                break;
            case 'category_3':
                let _filter3 = filterData.filters.category_3.filters.find(el => el.filterId === filterId);
                _filter3.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_3;

                let _count3 = 0;
                filterData.filters.category_3.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count3++;
                    }
                });
                filterData.filters.category_3.categoryCheckedNum = _count3;

                break;
            case 'category_4':
                let _filter4 = filterData.filters.category_4.filters.find(el => el.filterId === filterId);
                _filter4.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_4;

                let _count4 = 0;
                filterData.filters.category_4.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count4++;
                    }
                });
                filterData.filters.category_4.categoryCheckedNum = _count4;

                break;
            case 'category_5':
                let _filter5 = filterData.filters.category_5.filters.find(el => el.filterId === filterId);
                _filter5.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_5;

                let _count5 = 0;
                filterData.filters.category_5.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count5++;
                    }
                });
                filterData.filters.category_5.categoryCheckedNum = _count5;

                break;
            case 'category_6':
                let _filter6 = filterData.filters.category_6.filters.find(el => el.filterId === filterId);
                _filter6.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_6;

                let _count6 = 0;
                filterData.filters.category_6.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _count6++;
                    }
                });
                filterData.filters.category_6.categoryCheckedNum = _count6;
                break;

            case 'category_area':
                let _filterArea = filterData.filters.category_area.filters.find(el => el.filterId === filterId);
                _filterArea.checkAtdwProductFilter = value;
                filterData.currentFilterData = filterData.filters.category_area;

                let _countArea = 0;
                filterData.filters.category_area.filters.forEach(el => {
                    if (el.checkAtdwProductFilter === true) {
                        _countArea++;
                    }
                });
                filterData.filters.category_area.categoryCheckedNum = _countArea;
                break;
            case 'pet_friendly':
                filterData.petFriendly = value;
                break;
            default:
                break;
        }

        const _filteredData = [];
        let _filteredIdsString = "";

        filterData.filters.category_1.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_2.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_3.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_4.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_5.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_6.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filters.category_area.filters.forEach(el => {
            if (el.checkAtdwProductFilter === true) {
                _filteredData.push(el);
                _filteredIdsString = _filteredIdsString + el.filterId + ",";
            }
        });

        filterData.filteredData = _filteredData;
        filterData.filteredIdsString = _filteredIdsString;

        setFilterData({ ...filterData });
        const filteredDataResult = categoryFilter({ atdwDataItem: atdwState.reserveAtdwData });

        const _data = [];
        if (filteredDataResult.length > 0) {
            for (let x = 0; x < tilesSize; x++) {
                _data.push(filteredDataResult[x]);
                if (x === (filteredDataResult.length - 1)) {
                    break;
                }
            }
        }

        setAtdwState(pre => {
            let _new = { ...pre };
            _new.context.setupAtdwTxaCheckInDate(null);
            _new.filteredAtdwData = filteredDataResult;
            _new.atdwData = _data;
            _new.index = 1;
            _new.context.setupAtdwDataHistory({
                atdwDataFromDB: _new.atdwDataFromDB,
                reserveAtdwData: _new.reserveAtdwData,
                atdwData: _new.atdwData,
                index: _new.index
            });
            if (_data.length === 0) {
                setAtdwSearchText("Sorry, there are no available results.");
            }
            else {
                setAtdwSearchText("");
            }
            return _new;
        });
    }

    useEffect(() => {
        //console.log("useEffect_1");
        if (atdwState.context.atdwDataHistory2 !== null) {
            setAtdwState(pre => {
                let _new = { ...pre };
                _new.atdwDataFromDB = atdwState.context.atdwDataHistory.atdwDataFromDB;
                _new.reserveAtdwData = atdwState.context.atdwDataHistory.reserveAtdwData;
                _new.atdwData = atdwState.context.atdwDataHistory.atdwData;
                _new.index = atdwState.context.atdwDataHistory.index;
                return _new;
            });
            setTimeout(() => {
                document.getElementById(atdwState.context.atdwDataHistory2).scrollIntoView({ behavior: 'smooth', block: 'center' });
                atdwState.context.setupAtdwDataHistory2(null);
                atdwState.context.setupAtdwTxaCheckInDate(null);
            }, 500);

            return;
        }

        const fetchData = async () => {
            const res = await fetch(env.apiBase + "/api/atdw/getproductsv2post", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    area: atdwState.atdwArea,
                    customFilter: filter
                })
            });

            const data = await res.json();
            const _data = [];

            data.products.forEach(el => {
                const _attributes = el.additionalData.attributes || [];
                let checkPetFriendly = false;
                for (let _a in _attributes) {
                    if (_attributes[_a].attributeId === 'PETALLOW') {
                        checkPetFriendly = true;
                        break;
                    }
                }
                el.checkPetFriendly = checkPetFriendly;
            });

            var shuffled = JSON.parse(JSON.stringify(data.products));
            var currentIndex = shuffled.length, temporaryValue, randomIndex;

            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = shuffled[currentIndex];
                shuffled[currentIndex] = shuffled[randomIndex];
                shuffled[randomIndex] = temporaryValue;
            }

            let _filteredData = shuffled;

            _filteredData = searechAtdwFilter({
                petFriendly: (petFriendly && petFriendly === 'on') ? true : false,
                customCategories: (customCategories && customCategories !== '') ? customCategories : '',
                atdwDataItem: _filteredData
            });

            //shuffled
            if (_filteredData.length > 0) {
                for (var i = 0; i < tilesSize; i++) {
                    _data.push(_filteredData[i]);
                    if (i === (_filteredData.length - 1)) {
                        break;
                    }
                }
            }

            let _selectedFilter = null;
            let _currentFilter = "category_1";
            let _currentFilterData = data.atdwFilters.category_1;

            switch (filter) {
                case "category_1":
                    //filters: data.atdwFilters.category_1.
                    data.atdwFilters.category_1.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_1;
                    _currentFilter = "category_2";
                    _currentFilterData = data.atdwFilters.category_2;
                    break;
                case "category_2":
                    data.atdwFilters.category_2.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_2;
                    _currentFilter = "category_3";
                    _currentFilterData = data.atdwFilters.category_3;
                    break;
                case "category_3":
                    data.atdwFilters.category_3.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_3;
                    _currentFilter = "category_4";
                    _currentFilterData = data.atdwFilters.category_4;
                    break;
                case "category_4":
                    data.atdwFilters.category_4.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_4;
                    _currentFilter = "category_5";
                    _currentFilterData = data.atdwFilters.category_5;
                    break;
                case "category_5":
                    data.atdwFilters.category_5.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_5;
                    _currentFilter = "category_6";
                    _currentFilterData = data.atdwFilters.category_6;
                    break;
                case "category_6":
                    data.atdwFilters.category_6.categoryOpenCheck = true;
                    _selectedFilter = data.atdwFilters.category_6;
                    //_currentFilter = "category_6";
                    break;
                default:
                    break;  //CategoryCheck
            }
            
            setFilterData({
                currentFilter: _currentFilter,
                currentFilterData: _currentFilterData,
                filters: data.atdwFilters,
                petFriendly: false,
                filteredIdsString: '',
                filteredData: [],
                selectedFilter: _selectedFilter
            });

            setAtdwState(pre => {
                let _new = { ...pre };
                _new.atdwDataFromDB = data.products;
                _new.reserveAtdwData = _filteredData;
                _new.shuffledAtdwData = shuffled;
                _new.filteredAtdwData = _new.reserveAtdwData;
                _new.atdwData = _data;

                _new.context.setupAtdwDataHistory({
                    atdwDataFromDB: _new.atdwDataFromDB,
                    reserveAtdwData: _new.reserveAtdwData,
                    atdwData: _new.atdwData,
                    index: _new.index
                });

                return _new;
            });

            if (_data.length > 0) {
                setAtdwSearchText("");
            }
            else {
                setAtdwSearchText("Sorry, there are no available results.");
            }
        }
        fetchData();

        // eslint-disable-next-line
    }, [atdwState.atdwType, atdwState.context, atdwState.atdwArea]);

    useEffect(() => {
        if (trigger === 0) {
            return
        }
        const r_data = [];
        const _data = [];
        const fetchData = async () => {
            const sendData = {
                checkIn: checkIn,
                checkOut: checkOut,
                adults: numOfAdults,
                kids: numOfKids,
                txaList: []
            };

            atdwState.reserveAtdwData.forEach(el => {

                if (el.txaCode && el.txaCode !== '') {
                    sendData.txaList.push(el.txaCode);
                }
            });

            const res = await fetch(env.apiBase + "/api/atdw/gettxaproducts", {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(sendData)
            });

            const data = await res.json();

            //let _count = 0;
            for (var i = 0; i < atdwState.reserveAtdwData.length; i++) {
                if (atdwState.reserveAtdwData[i].txaCode !== null && atdwState.reserveAtdwData[i].txaCode !== '') {
                    if (data.toString().includes(atdwState.reserveAtdwData[i].txaCode)) {
                        r_data.push(atdwState.reserveAtdwData[i]);
                        // if (_count < tilesSize) {
                        //     _data.push(atdwState.reserveAtdwData[i]);
                        // }
                        // _count++;
                    }
                }
            }

            setAtdwState(pre => {
                let _new = { ...pre };
                _new.reserveAtdwData = r_data;
                const filteredDataResult = categoryFilter({ atdwDataItem: _new.reserveAtdwData });

                if (filteredDataResult.length > 0) {
                    for (let x = 0; x < tilesSize; x++) {
                        _data.push(filteredDataResult[x]);
                        if (x === (filteredDataResult.length - 1)) {
                            break;
                        }
                    }
                }

                _new.atdwData = _data;
                _new.index = 1;
                _new.filteredAtdwData = filteredDataResult;

                return _new;
            });

            if (_data.length === 0) {
                setAtdwSearchText("Sorry, there are no available results.");
            }
            else {
                setAtdwSearchText("");
            }
        };

        fetchData();
        setTrigger(0);

        // eslint-disable-next-line
    }, [trigger, checkIn, checkOut, numOfAdults, numOfKids, atdwState.reserveAtdwData]);

    const addAtdw = useCallback((reserveAtdwData, _atdwContext) => {
        //console.log("addAtdw", reserveAtdwData);
        const av = [];
        for (var i = (atdwState.index * tilesSize); i < ((atdwState.index * tilesSize) + tilesSize); i++) {
            av.push(reserveAtdwData[i]);
            if (reserveAtdwData.length - 1 === i) {
                break;
            }
        }

        setAtdwState(pre => {
            let _new = { ...pre };
            _new.atdwData = [..._new.atdwData, ...av];
            _new.index = _new.index + 1;
            _atdwContext.setupAtdwDataHistory({
                atdwDataFromDB: _new.atdwDataFromDB,
                reserveAtdwData: _new.reserveAtdwData,
                atdwData: _new.atdwData,
                index: _new.index
            });
            return _new;
        });
    }, [atdwState.index]);

    return (
        <Fragment>
            {
                openPopup &&
                <Portal>
                    <div css={style.portal__background} onClick={closePopupFun}></div>
                    <div css={style.portal__popupcontainer}>
                        <div css={style.select__attribute__outer}>
                            <div css={style.select__attribute}>

                                <div css={style.close__btn} onClick={closePopupFun}>
                                    <MdClose />
                                </div>

                                <div css={style.search__text}>
                                    {'Search filters - showing ' + atdwState.filteredAtdwData.length + ' of ' + atdwState.atdwDataFromDB.length + ((filterData.selectedFilter && filterData.selectedFilter !== null) ? " - " + filterData.selectedFilter.categoryName : '')}
                                </div>

                                {
                                    filterData &&
                                    <Fragment>
                                        <div css={style.category__button__container}>
                                            {
                                                filterData.filters.category_1.categoryOpenCheck === false &&
                                                filterData.filters.category_2.categoryOpenCheck === false &&
                                                filterData.filters.category_3.categoryOpenCheck === false &&
                                                filterData.filters.category_4.categoryOpenCheck === false &&
                                                filterData.filters.category_5.categoryOpenCheck === false &&
                                                filterData.filters.category_6.categoryOpenCheck === false &&
                                                <div css={style.no__filter__selected__text}>
                                                    (No filters selected)
                                                </div>
                                            }

                                            <div css={style.show__result} className='show__result__top'>
                                                <div onClick={closePopupFun}>
                                                    {'Show ' + atdwState.filteredAtdwData.length + ' results'}
                                                </div>
                                            </div>

                                            {
                                                filterData.selectedFilter && filterData.selectedFilter !== null &&
                                                <div css={style.category__button__item__wrapper}>
                                                    <div css={style.category__button__item} className={`${filterData.selectedFilter.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('select__filter');
                                                    }}>
                                                        {filterData.selectedFilter.categoryName + (filterData.selectedFilter.categoryCheckedNum > 0 ? ` (${filterData.selectedFilter.categoryCheckedNum})` : '')}
                                                        {/* {filterData.filters.category_1.categoryName + (filterData.filters.category_1.CategoryCheckedNum > 0 ? `(${filterData.filters.category_1.CategoryCheckedNum})` : '')} */}
                                                    </div>

                                                    {
                                                        filterData.selectedFilter.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.selectedFilter.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.selectedFilter.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.selectedFilter.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                filterData.selectedFilter && filterData.selectedFilter !== null &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__text">
                                                    Additional Filters
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_1')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_1.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_1');
                                                    }}>
                                                        {filterData.filters.category_1.categoryName + (filterData.filters.category_1.categoryCheckedNum > 0 ? ` (${filterData.filters.category_1.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_1.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_1.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_1.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_1.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_2')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_2.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_2');
                                                    }}>
                                                        {filterData.filters.category_2.categoryName + (filterData.filters.category_2.categoryCheckedNum > 0 ? ` (${filterData.filters.category_2.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_2.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_2.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_2.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_2.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_3')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_3.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_3');
                                                    }}>
                                                        {filterData.filters.category_3.categoryName + (filterData.filters.category_3.categoryCheckedNum > 0 ? ` (${filterData.filters.category_3.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_3.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_3.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_3.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_3.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_4')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_4.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_4');
                                                    }}>
                                                        {filterData.filters.category_4.categoryName + (filterData.filters.category_4.categoryCheckedNum > 0 ? ` (${filterData.filters.category_4.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_4.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_4.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_4.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_4.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_5')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_5.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_5');
                                                    }}>
                                                        {filterData.filters.category_5.categoryName + (filterData.filters.category_5.categoryCheckedNum > 0 ? ` (${filterData.filters.category_5.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_5.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_5.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_5.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_5.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_6')) &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_6.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_6');
                                                    }}>
                                                        {filterData.filters.category_6.categoryName + (filterData.filters.category_6.categoryCheckedNum > 0 ? ` (${filterData.filters.category_6.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_6.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_6.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_6.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_6.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                            {
                                                atdwState.atdwArea === 'all' &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__item">
                                                    <div css={style.category__button__item} className={`${filterData.filters.category_area.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('category_area');
                                                    }}>
                                                        {filterData.filters.category_area.categoryName + (filterData.filters.category_area.categoryCheckedNum > 0 ? ` (${filterData.filters.category_area.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.filters.category_area.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.filters.category_area.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.filters.category_area.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.filters.category_area.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }

                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </div>
                                            }

                                        </div>

                                        <div className="form-group" css={style.category__select__container}>

                                            {
                                                filterData.selectedFilter && filterData.selectedFilter !== null &&
                                                <Fragment>
                                                    <div css={style.category__button__item} className={`${filterData.selectedFilter.categoryOpenCheck === true && 'seleted'}`} onClick={() => {
                                                        changeFilter('select__filter');
                                                    }}>
                                                        {filterData.selectedFilter.categoryName + (filterData.selectedFilter.categoryCheckedNum > 0 ? `(${filterData.selectedFilter.categoryCheckedNum})` : '')}
                                                    </div>
                                                    {
                                                        filterData.selectedFilter.categoryOpenCheck === true &&
                                                        <Fragment>
                                                            <hr css={style.hr__style} />
                                                            {
                                                                filterData.selectedFilter.filters.length > 0 ?
                                                                    <div css={style.check__box__group} className="form-group">
                                                                        {
                                                                            filterData.selectedFilter.filters.map((item, index) =>
                                                                                <FilterItem key={index} filter={item} categoryCode={filterData.selectedFilter.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                                        }
                                                                    </div> : <div css={style.no__filter__text} style={{ textAlign: 'left' }}>No Filters</div>
                                                            }
                                                        </Fragment>
                                                    }
                                                </Fragment>
                                            }

                                            {
                                                filterData.selectedFilter && filterData.selectedFilter !== null &&
                                                <div css={style.category__button__item__wrapper} className="additional__filter__text">
                                                    Additional Filters
                                                </div>
                                            }

                                            <select className="form-control form-control-sm" id="filter__select" name="filter__select" value={filterData.currentFilter} onChange={handChange}>

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_1')) &&
                                                    <option value="category_1"> Family Friendly </option>
                                                }

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_2')) &&
                                                    <option value="category_2"> Waterfront </option>
                                                }

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_3')) &&
                                                    <option value="category_3"> Bush </option>
                                                }

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_4')) &&
                                                    <option value="category_4"> Style </option>
                                                }

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_5')) &&
                                                    <option value="category_5"> EV </option>
                                                }

                                                {
                                                    (!filterData.selectedFilter || (filterData.selectedFilter && filterData.selectedFilter.categoryCode !== 'category_6')) &&
                                                    <option value="category_6"> Pet Friendly </option>
                                                }

                                            </select>

                                            {
                                                filterData.currentFilterData.filters.length > 0 ?
                                                    <div css={style.check__box__group} className="form-group">
                                                        {
                                                            filterData.currentFilterData.filters.map((item, index) => <FilterItem key={index} filter={item} categoryCode={filterData.currentFilterData.categoryCode} handChangeForFilter={handChangeForFilter} />)
                                                        }
                                                    </div> : <div style={{ textAlign: 'center' }}>No Filters</div>
                                            }

                                        </div>

                                        <div css={style.show__result}>
                                            <div onClick={closePopupFun}>
                                                {'Show ' + atdwState.filteredAtdwData.length + ' results'}
                                            </div>
                                        </div>

                                    </Fragment>
                                }
                            </div>
                        </div>
                    </div>
                </Portal>
            }
            <section style={{ backgroundColor: `${bgColor}`, backgroundImage: bgUrl && utils.css.bgUrlStyle(bgUrl), padding: '1rem 0px' }} className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
                <div className="container">

                    {/* <AtdwSearchTitle type={atdwState.atdwType} /> */}
                    <AtdwSearchOptionV2 atdwType={atdwState.atdwType} onGetSearchOption={onGetSearchOptionFun} petFriendly={petFriendly} openPopupFun={openPopupFun} closePopupFun={closePopupFun} />
                    {
                        filterData && (filterData.filteredData.length > 0 || filterData.petFriendly === true) &&
                        <AppliedSearchFilters filteredData={filterData.filteredData} petFriendly={filterData.petFriendly} handChangeForFilter={handChangeForFilter} />
                    }

                    {
                        atdwSearchText === "" &&
                        <div style={{ marginLeft: '1rem' }}>
                            1 to {atdwState.atdwData.length} of {atdwState.filteredAtdwData.length}
                        </div>
                    }

                    {atdwState.atdwData ? (
                        <AtdwSearchResult atdwData={atdwState.atdwData} />
                    ) : null}

                    {atdwState.filteredAtdwData.length >= 12 && atdwSearchText === "" ? (
                        <div style={{ marginRight: "1rem", textAlign: 'right' }}>
                            1 to {atdwState.atdwData.length} of {atdwState.filteredAtdwData.length}
                        </div>
                    ) : null}

                    {
                        atdwState.atdwData.length !== atdwState.filteredAtdwData.length && atdwState.atdwData.length > 0 ? (
                            <div style={{ textAlign: 'center' }}>
                                <button className="btn btn-primary" type="button" onClick={() => {
                                    addAtdw(atdwState.filteredAtdwData, atdwState.context);
                                }}>MORE</button>
                            </div>
                        ) : null}

                    {
                        atdwSearchText !== "" &&
                        <div style={{ textAlign: 'center', margin: '1rem 0px' }}>
                            {atdwSearchText}
                        </div>
                    }

                </div>
            </section>
        </Fragment>
    );
}

function FilterItem({ filter, categoryCode, handChangeForFilter }) {
    return (
        <div css={style.pet__container}>
            <div className="form-check" css={style.pet}>
                <input className="form-check-input" type="checkbox" id={filter.filterId} name={filter.filterId} checked={filter.checkAtdwProductFilter} onChange={(e) => {
                    handChangeForFilter({
                        filterId: filter.filterId,
                        value: e.target.checked,
                        categoryCode: categoryCode
                    });
                }} />
                <label className="form-check-label" htmlFor={filter.filterId}>
                    {filter.filterName}
                </label>
            </div>
        </div>
    )
}

function AppliedSearchFilters({ filteredData, petFriendly, handChangeForFilter }) {
    return (
        <div css={style.applied__filters}>
            {/* {
                petFriendly === true &&
                <div css={style.applied__filter__item}>
                    Pet Friendly
                    <MdClose onClick={(e) => {
                        handChangeForFilter({
                            value: false,
                            categoryCode: "pet_friendly"
                        });
                    }} />
                </div>
            } */}
            {
                filteredData.map((item, index) =>
                    <div key={index} css={style.applied__filter__item}>
                        {item.filterName}
                        <MdClose onClick={(e) => {
                            handChangeForFilter({
                                filterId: item.filterId,
                                value: false,
                                categoryCode: item.categoryCode
                            });
                        }} />
                    </div>
                )
            }
        </div>
    )
}