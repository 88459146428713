import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    tileSize : css(mq({
        //border : '1px solid red',
        position : 'relative',
        height : ['300px', '450px'],
        backgroundImage:'url("/assets/logos/logo.png")',
        backgroundPosition:'center',
        backgroundSize:'contain',
        backgroundRepeat: 'no-repeat',
        'img' : {
            //border : '1px solid red'
            position : 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            '@media(max-width:600px)' : {
                height : '100%',
                width: 'auto'
            }
        }
    })),
    productName : css({
        //textAlign : 'center',
        margin : '0px'
    }),
    serviceName : css({
        //textAlign : 'center',
        margin : '0px'
    }),
    serviceDes : css({
        marginTop:'1rem',
        marginBottom:'1rem'
    }),
    banerIconsBar : css({
        textAlign : 'center',
        backgroundColor : '#fe3a43',
        padding : '0.3rem 0',
        display : 'flex',
        flexDirection : 'row',
        flexWrap : 'wrap',
        justifyContent : 'center',
        'svg' : {
            width : '32px',
            height : '32px',
            color : 'white'
        },
        // '.bar__item' : {
        //     margin : '0px 20px',
        //     cursor : 'pointer',
        //     '&:hover' : {
        //         textDecoration : 'unset'
        //     }
        // },
        '.bar__item' : mq({
            margin : ['0px 15px', null, '0px 20px'],
            cursor : 'pointer',
            '&:hover' : {
            textDecoration : 'unset'
            }
        }),
        '.bar__item.booking__button' :mq({
            margin : ['0px 10px', null, '0px 15px'],
        }),
        '.icon__text' : {
            fontSize : '.6rem',
            color : '#fff'
        },
        '@media(max-width:600px)' : {
            marginTop:'-7px'
        }
        // '&.dock' : mq({
        //     position:'fixed',
        //     top:['50px', '44px', '56px'],
        //     width: '100%',
        //     zIndex:'9'
        // })
    }),
    contact_text : css(mq({
        fontSize: ['1.6rem', '1.8rem']
    })),
    gps_text : css(mq({
        display: ['inline-block', 'inline']
    })),
    info : css({
        'ul, li' : {
            listStyle: 'none',
            margin: '0',
            padding: '0',
            display: 'inline-block',
            'li' : {
                margin: '0 .7rem 0 0',
                padding: '0 .7rem 0 0',
                listStyle: 'none',
                borderRight: '1px solid black',
                lineHeight: '1',
                '&:last-of-type' : {
                    borderRight: '0'
                }
            }
        },
        marginBottom:'0.5rem'
    }),
    flag : css({
        marginTop:'1rem',
        marginBottom:'0.5rem',
        'ul, li' : {
            listStyle: 'none',
            margin: '0',
            padding: '0',
            display: 'inline-block',
            'li' : {
                margin: '0 .7rem 0 0',
                padding: '0 .7rem 0 0',
                listStyle: 'none',
                //borderRight: '1px solid black',
                lineHeight: '1',
                // '&:last-of-type' : {
                //     borderRight: '0'
                // }
                '&:last-child' : {
                    marginRight: '0px',
                    paddingRight:'0px'
                }
            }
        }
    }),

    booking__button : {
        svg : {
            transition: 'all 0.5s ease-in-out'
        },
        '&:hover' : {
            svg:{
                transform:'translateY(-3px)'
            },
            img : {
                transform:'scale(1.2)'
            }
        }
    },
    booking_button_image : mq({
        height: '32px',
        transition: 'all 0.5s ease-in-out',
        //margin : ['0px 10px', null, '0px 15px'],
    })
}