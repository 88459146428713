import React from 'react';
import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import { SiteLink } from 'components';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  //console.log('item', item);

  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', item.cssClass || item.anchorName || '');
  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));

  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');
  const captionbutton = cmsUtils.payload(item, 'CaptionButton');

  // const titlePanel = captionTitle ? (
  //   linkUrl ? (<SiteLink to={linkUrl} className="banner-item__link">
  //     <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
  //   </SiteLink>) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  // ) : null;

  // const titlePanel = captionTitle ? (
  //   linkUrl ? (<SiteLink to={linkUrl} className="banner-item__link">
  //     <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
  //   </SiteLink>) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  // ) : (captionbutton ? <div className = "banner-item__title"> {captionbutton} </div> : null)

  const titlePanel = captionTitle ? (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>) : (captionbutton ? <div className = "banner-item__title"> {captionbutton} </div> : null);

  return (
    linkUrl ? (
      <SiteLink to = {linkUrl}>
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
          style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
          {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      </div>
      </SiteLink>) : (
      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
        style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
        {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
      </div>)
    // <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}
    //   style={{ backgroundImage: utils.css.bgUrlStyle(bgUrl) }}>
    //   {titlePanel && <div className="banner_item__text">{titlePanel}</div>}
    // </div>
  );
  
}