/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useEffect, Fragment } from 'react';
import env from 'env';
import { SiteLink} from 'components';
import { usePost } from 'components';
import { ErrorPopup, Loading } from 'components';
import { useRouter } from 'components';
import { AtdwSearchResult } from '../atdw/searchResult/view';

export function SearchResult(props) {
  
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;
  //const keyword = query.search;
  //console.log(keyword);

  useEffect(() => {
    
    console.log('keyword', keyword);
    
    post.send(env.apiBase + '/api/search/search', { keyword: keyword });
    
    
    
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  const data = post.response;
  console.log("data", data);
  const pages = data.pages;
  const atdw = data.atdw;

  // if (pages.length === 0) return (
  //   <div className="alert alert-danger" role="alert">
  //     No result found.
  //   </div>
  // )
  return (
    <Fragment>
      {
        (pages.length > 0 || atdw.length > 0) ?
        <Fragment>
          {
            atdw.length > 0 &&
            <div>
              <h3 style={{marginTop: '2rem'}}>ATDW</h3>
              <AtdwSearchResult atdwData = {atdw} fromCalled = "search" ></AtdwSearchResult>
            </div>
          } 
          {
            pages.length > 0 &&
            <div>
              <h3 style={{marginTop: '2rem'}}>Pages</h3>
              <PageList pages={pages} />
            </div>
          }
        </Fragment> :
        <div className="alert alert-danger" role="alert">
          No results
        </div>
      }
    </Fragment>
  );
}

function PageList({pages}) {
  return (
    <div className="pageList row">
      {pages.map((page, index) =>
        <div className="pageListItem col-md-6 col-lg-4" key={index}>
          <div className="pageListItem__inner">
            <SiteLink className="pageListItem__title" to={page.pageUrl}>{page.pageTitle}</SiteLink>
            <div className="pageListItem__highlight" dangerouslySetInnerHTML={{ __html: page.searchHighlight }}></div>
          </div>
        </div>
      )}
    </div>
  );
}