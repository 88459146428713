import { css } from '@emotion/core'
//import { mq } from 'cssInJs'
export default{
    formContainer : css({
        maxWidth:'550px',
        margin: 'auto',
        padding: '1rem 0px'
    }),
    textContainer : css({
        marginBottom:'1rem'
    }),
    buttonStyle : css({
        width: '100%'
    })
}