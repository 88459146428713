import { mq } from 'cssInJs';

export default {
    portal__background: {
        backgroundColor: 'rgba(0,0,0,0.3)',
        position: 'fixed',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
        zIndex: '9998'
    },
    portal__popupcontainer: mq({
        position: 'fixed',
        top: '2%',
        left: '50%',
        width: '100%',
        zIndex: '9999',
        margin: '0px',
        transform: 'translateX(-50%)',
        maxWidth: ['95%', null, '800px', null, '1200px'],
    }),
    select__attribute__outer: {
        backgroundColor: 'white',
        maxWidth: '1200px',
        margin: 'auto',
        padding: '1rem',
        borderRadius: '10px',
    },
    select__attribute: {

        //textAlign:'center',
        //maxWidth: ['320px', null, '820px'],

        maxHeight: '700px',
        overflow: 'auto'
    },
    no__filter__selected__text: {
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        left: '50%',
        fontSize: '1.2rem',
        fontWeight: 700
    },
    category__button__container: mq({
        //display: 'flex',
        marginTop: '1rem',
        display: ['none', null, 'block'],
        position: 'relative'
        //flexDirection: 'column'
    }),
    category__select__container: mq({
        //display: 'flex',
        marginTop: '1rem',
        display: ['block', null, 'none'],
        'select': {
            fontWeight: 700,
            fontSize: '1.1rem',
            //background: 'red !important',
            // 'option:checked' : {
            //     backgroundColor : '#fe3a43',
            //     color: 'white'
            // },
            // 'option:hover' : {
            //     //box-shadow: 0 0 10px 100px #1882A8 inset
            //     boxShadow: '0 0 10px 100px #1882A8 inset !important',
            //     background : 'red !important',
            //     outline: 'none !important'
            // }
        }
        // 'select : hover' : {
        //     background: 'red !important',
        //     outline: 'none !important'
        // },
        // '::selection' : {
        //     color :'red',
        //     backgroundColor : 'red !important'
        // }
    }),

    category__button__item__wrapper: {
        flex: '1 0 100%',
        maxWidth: '100%',
        '&.additional__filter__text': {
            color: '#fe3a43',
            marginTop: '3rem',
            marginBottom: '1rem',
            fontSize: '0.8rem',
            fontWeight: '700'
        },
        '&.additional__filter__item': {
            marginTop: '1rem',
            //marginBottom : '1rem',
        },
    },

    show__result: {
        textAlign: 'right',
        '&.show__result__top': {
            position: 'absolute',
            right: '0px'
        },
        'div': {
            paddingLeft: '1rem',
            paddingRight: '1rem',
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: 600,
            lineHeight: 2,
            borderRadius: '10px',
            backgroundColor: "#fe3a43",
            color: 'white',
            display: 'inline-block',
        }
    },

    category__button__item: {
        // flex: '1 0 20%',
        // maxWidth : '20%',
        paddingLeft: '1.5rem',
        paddingRight: '1.5rem',
        textAlign: 'center',
        cursor: 'pointer',
        fontWeight: 600,
        lineHeight: 2,
        borderRadius: '10px',
        backgroundColor: "#333333",
        color: 'white',
        display: 'inline-block',
        maxWidth: '200px',
        width: '100%',
        '&.seleted': {
            backgroundColor: "#fe3a43",
            color: 'white',
            //pointerEvents: 'none'
        }
    },
    category__button__item__inner: {
        '&.seleted': {
            backgroundColor: 'red'
        }
    },
    hr__style: {
        borderTop: '2px solid rgba(0,0,0,0.1)',
        marginTop: '0.7rem',
        marginBottom: '0.7rem'
    },

    check__box__group: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '1.5rem',
        maxHeight: '500px',
        overflow: 'auto'
    },

    pet__container: mq({
        flex: ['1 0 100%', null, '1 0 33.3%'],
        maxWidth: ['100%', null, '33.3%'],
        paddingTop: ['0.3rem', null, '0px'],
        paddingBottom: ['0.3rem', null, '0px'],
        paddingLeft: ['3px', null, '0px'],
        paddingRight: ['3px', null, '0px']
    }),

    pet: {
        //marginTop:'2.5rem',
        paddingLeft: '2rem',
        'input': {
            accentColor: "#fe3a43"
        },
        '.form-check-input': mq({
            width: '20px',
            height: '20px',
            marginTop: ['3px', null, '0.3rem'],
            marginLeft: '-2rem'
        }),
        // '.form-check-label' : css({
        //     fontSize:'1.5rem',
        //     fontWeight: '700'
        // })
    },

    search__text: {
        fontWeight: 700,
        color: "#fe3a43",
        fontSize: '1.2rem',
        marginTop: '0.2rem',
        position: 'relative',
    },

    close__btn: {
        textAlign: 'right',
        cursor: 'pointer',
        'svg': {
            width: '35px',
            height: '35px'
        }
    },
    s__filter__container: {
        marginTop: '0.5rem',
        marginBottom: '0px',
        backgroundColor: '#ebebeb',
        padding: '0.5rem 1rem',
        borderRadius: '5px'
    },
    applied__filters: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '1rem 0.5rem',
        backgroundColor: '#ebebeb',
        marginBottom: '1rem',
        borderRadius: '10px'
    },
    applied__filter__item: {
        marginLeft: '0.5rem',
        marginRight: '0.5rem',
        marginTop: '0.3rem',
        marginBottom: '0.3rem',
        backgroundColor: '#fe3a43',
        color: 'white',
        padding: '0.1rem 0.5rem',
        paddingTop: '0px',
        borderRadius: '5px',
        'svg': {
            marginLeft: '0.5rem',
            marginBottom: '2px',
            cursor: 'pointer'
        }
    },
    no__filter__text: {
        marginBottom: '1rem'
    }
}