import variables from 'cssInJs/variables';

const cssEmotion = {
  breakpointNames: variables.breakpointNames,
  breakpoints: variables.breakpoints
}

const firebaseSettings = {
  apiKey: "AIzaSyCf-AIHxcfc67ZNkQB9G1A-ovhl5aVtUgI",
  authDomain: "jaba-saparks.firebaseapp.com",
  databaseURL: "https://jaba-saparks.firebaseio.com",
  projectId: "jaba-saparks",
  storageBucket: "jaba-saparks.appspot.com",
  messagingSenderId: "888871261278",
  appId: "1:888871261278:web:f76ac0ce159ebc2d4fd0cf",
}

export default {
  cssEmotion,
  firebaseSettings
}