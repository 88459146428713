/** @jsx jsx */
import { jsx } from '@emotion/core';
//import { SiteLink } from 'components';
//import cmsUtils from 'cms/utils/cmsUtils';
import  utils from 'utils';
import style from './style';
import { Link } from 'react-router-dom';

export function ServiceTile(props){
    const service = props.service;
    const atdwName = props.atdwName;
    const slug = props.slug;
    const txacheckindate = props.txacheckindate;
    
    const getImageUrl = (() => {
        let imgUrl = '/assets/logos/logo.png';
        for(var i in service.serviceMultimedia){
            if(service.serviceMultimedia[i].attributeIdSizeOrientation === '4X3' && parseInt(service.serviceMultimedia[i].width) <= 800){
                imgUrl = service.serviceMultimedia[i].serverPath;
                break;
            }
        }
        return imgUrl;
    });
    
    return(
        //id={product.productId}
        //+ product.slug
        slug ?
        <div css={style.serviceTile} className="serviceTile">
            <Link to={'/atdwproduct/service/' + slug.serviceSlug + ((txacheckindate && txacheckindate !== '') ? '?txacheckindate=' + txacheckindate : '') }>
                <div css={style.bgContainer} className="bg_container">
                    <div css={style.bg} className="bg_holder" style={{backgroundImage:'url("/assets/logos/logo.png")'}}></div>
                    <div css={style.bg} className="bg" style={{ backgroundImage: utils.css.bgUrlStyle(getImageUrl()) }}>
                        <div css={style.viewMore} className="viewMore">
                            VIEW MORE
                        </div>
                    </div>
                </div>

                <div css={style.town} className="town">
                    { atdwName }
                </div>
                <div css={style.descContainer}  className="desc-container">
                    <div css={style.title} className="title">
                        { service.serviceName }
                    </div>
                    <hr />
                    <div css={style.shotDesc} className="shot-desc">
                        { service.serviceDescription }
                    </div>
                </div>
            </Link>
        </div> : null
    )
}