/** @jsx jsx */
import { jsx } from '@emotion/core'
import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
import { MdDirections, MdCall, MdWeb, MdBook } from "react-icons/md";
import style from './style';

export function NonAtdwPageInfoBar(props){
    const item = props.item;
    //console.log("item", item);
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'nonatdwpageinfobar', item.cssClass || item.anchorName || '');

    const phone = cmsUtils.payload(item, "Phone") || "";
    //const location = cmsUtils.payload(item, "Location") || "";
    const website = cmsUtils.payload(item, "Website") || "";
    const bookings = cmsUtils.payload(item, "Bookings") || "";

    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            <div css={style.banerIconsBar} className="atdw__page__banerIconsBar none__atdw" id="atdwpagebanericonsbar">
                <a href={'tel:+' + phone} className="bar__item">
                    <MdCall />
                    <div className="icon__text">PHONE</div>
                </a>

                <div className="bar__item" onClick={()=>{
                    if(document.getElementById('atdwpagelocation')){
                        document.getElementById('atdwpagelocation').scrollIntoView({behavior:'smooth', block:'center'});
                    }
                }}>
                    <MdDirections/>
                    <div className="icon__text">LOCATION</div>
                </div>

                <a href={website} className="bar__item" target="_blank" rel="noopener noreferrer">
                    <MdWeb />
                    <div className="icon__text">WEBSITE</div>
                </a>

                <a href={bookings} className="bar__item" target="_blank" rel="noopener noreferrer">
                    <MdBook />
                    <div className="icon__text">BOOKINGS</div>
                </a>
            </div>
        </div>
    )
}