/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import { useState, useEffect, Fragment } from 'react';
import env from 'env';
import { Redirect } from 'react-router-dom';
import { Loading } from 'components';

export function Register(props){

    const [info, setInfo] = useState({
        organisation : '',
        name : '',
        password : '',
        passwordClue : '',
        email : '',
        check : false
    });
    const [buttonTrigger, setButtonTrigger] = useState(0);
    const [msg, setMsg] = useState();
    const [redirect, setRedirect] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    function onSubmit(e) {
        e.preventDefault();   
        e.stopPropagation();

        if(!info.check){
            setMsg("Please check box");
            return;
        }
        setButtonTrigger(buttonTrigger + 1);
        setPageLoading(true);
    }

    const handChange = (el => {
        const elName = el.target.name;
        const elValue =el.target.value;
        setInfo({...info, [elName] : elValue});
    });

    useEffect(() => {
        if(buttonTrigger === 0){
            return;
        }
        const fetchData = async () => {
            const res = await fetch(env.apiBase + '/api/profile/Register', {
                method : 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(info)
            });
            const data = await res.json();
            if(data.errors.length > 0){
                setMsg(data.errors[0].errorMessage);
            }
            else{
                setRedirect(true);
            }
            setPageLoading(false);
        }
        fetchData();
        setButtonTrigger(0);
    }, [buttonTrigger, info]);

    return(
        pageLoading ? <Loading></Loading> :
        <Fragment>
            <h1 style={{textAlign:'center',padding: '0px 1rem'}}>Register now to access the Members area</h1>
        <form css={style.formContainer} autoComplete="off" onSubmit = {onSubmit}>
            <div className="form-group">
                <label htmlFor="username"> Organisation * </label>
                <input className="form-control" name="organisation" onChange={handChange} value={info.organisation} required />
            </div>

            <div className="form-group">
                <label htmlFor="username"> Name * </label>
                <input className="form-control" name="name" onChange={handChange} value={info.name} required />
            </div>

            <div className="form-group">
                <label htmlFor="password"> Password * </label>
                <input className="form-control" name="password" onChange={handChange} value={info.password} required />
            </div>

            <div className="form-group">
                <label htmlFor="password"> Password Clue * </label>
                <input className="form-control" name="passwordClue" onChange={handChange} value={info.passwordClue} required />
            </div>

            <div className="form-group">
                <label htmlFor="username"> Email * </label>
                <input className="form-control" name="email" onChange={handChange} value={info.email} required />
            </div>

            <div className="form-group form-check">
                <input type="checkbox" className="form-check-input" name="check" id="check" onChange={(e)=>{
                        handChange({target : {name : "check", value : e.target.checked}});
                }} checked={info.check} />
                <label className="form-check-label" htmlFor="check"> I am NOT a spambot! </label>
            </div>

            {
                msg ?
                <div>
                    {msg}
                </div> : null
            }

            {
                redirect ? <Redirect to="/thanks-register"></Redirect> : null
            }

            <div className="form-group" style={{textAlign:'center'}}>
                <button type="submit" className="btn btn-primary">
                    REGISTER
                </button>
            </div>
        </form>
        </Fragment>
    )    
}