import { css } from '@emotion/core'
//import { mq } from 'cssInJs'
export default{
    banerIconsBar : css({
        textAlign : 'center',
        backgroundColor : '#fe3a43',
        padding : '0.3rem 0',
        display : 'flex',
        flexDirection : 'row',
        flexWrap : 'wrap',
        justifyContent : 'center',
        'svg' : {
            width : '32px',
            height : '32px',
            color : 'white'
        },
        '.bar__item' : {
            margin : '0px 20px',
            cursor : 'pointer',
            '&:hover' : {
                textDecoration : 'unset'
            }
        },
        '.icon__text' : {
            fontSize : '.6rem',
            color : '#fff'
        },
        '&.atdw__item' : {
            '@media(max-width:600px)' : {
                marginTop:'-7px'
            }
        }
    })
}