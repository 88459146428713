/** @jsx jsx */
import { jsx } from '@emotion/core'
//import cmsUtils from '../../cms/utils/cmsUtils';
import utils from '../../utils';
//import style from './style';
import { Login as LoginA, useAuth } from '../../auth/index'
import { useRouter, SiteLink } from 'components';

export function Login(props){
    const item = props.item;
    const cmsOption = props.cmsOption || {};
    const cssClass = utils.classNames('cms_item', 'login', item.cssClass || item.anchorName || '');
    const _useAusth = useAuth();
    const router = useRouter();
    
    return(
        <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}>
            {
                cmsOption.isCmsEdit ?
                <pre style={{fontSize: '12px', margin:'5px', padding: '10px', background:'#eee', border: '1px solid #ccc'}}>
                    <b> Login Item </b>
                </pre> :
                (_useAusth.session && !_useAusth.session.expired() ?
                    <div style={{textAlign:'center'}}>
                        <button className="btn btn-primary" type="button" onClick={()=>{
                            _useAusth.signout().then(user => {
                                router.push('/members-login')
                            });
                        }}>Log out</button>
                    </div> :
                    // <form css={style.formContainer} autoComplete="off">
                    //     <div className="form-group">
                    //         <label htmlFor="username"> Email </label>
                    //         <input className="form-control" className="form-control" />
                    //     </div>
                    //     <div className="form-group">
                    //         <label htmlFor="password"> Password </label>
                    //         <input type="password" className="form-control" />
                    //     </div>
                    //     <div className="login-msg-info"></div>
                    //     <div className="form-group" style={{textAlign:'center'}}>
                    //         <button type="submit" className="btn btn-primary">
                    //             Log In
                    //         </button>
                    //     </div>
                    // </form>
                    <div>
                        <LoginA />
                        <div style={{maxWidth:'600px',margin:'auto',marginTop:'1rem'}}>
                            <SiteLink to = '/auth/forgot-password'>
                                Forgot Password?
                            </SiteLink>
                            <SiteLink to = '/about-sa-parks' style={{marginLeft: '1rem'}}>
                                About SA Parks
                            </SiteLink>
                        </div>
                    </div>
                )
            }
        </div>
    )
}