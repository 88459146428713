import { css } from '@emotion/core'
import { mq } from 'cssInJs'

export default {
    
    atdwTile : css(mq({
        flexGrow: '1',
        flexShrink: '0',
        flexBasis: ['260px', '260px', '334px', '299px', '267px'],
        maxWidth: ['100%', '100%', '334px', '299px', '267px'],
        margin: '10px 5px',
        backgroundColor : '#ebebeb',
        '& a' : {
            textDecoration : 'none',
            color : '#252525'
        },
        'hr' : {
            height: '2px',
            border: '0px',
            backgroundImage: 'linear-gradient(90deg,#fe3a43,transparent)'
        }
    })),
    bg : css({
        paddingTop: '60%',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position:'relative'
    }),
    petIcon : css({
        position:'absolute',
        top:'10px',
        left:'10px',
        width: '50px',
        zIndex:'1'
    }),
    petIconBg : css({
        width: '100%',
        height:'100%',
        position:'absolute',
        top:'0px',
        //backgroundColor:'rgba(0,0,0,0.3)'
    }),
    town : css({
        padding : '0rem 0.5rem',
        backgroundColor : '#fe3a43',
        fontWeight : '700',
        color:'white'
    }),
    title : css({
        lineHeight:'1',
        fontWeight:'700',
        fontSize:'0.9rem',
        marginTop: '0.1rem'
    }),
    descContainer : css({
        padding : '0.2rem 0.5rem',
        paddingBottom : '0.5em',
        '& hr' :{
            margin : '0.3rem 0rem'
        }
    }),
    desc : css({
        fontSize : '0.8rem'
    }),
    shotDesc : css({
        fontSize : '0.8rem',
        marginTop: '0.2rem',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        WebkitLineClamp: '3',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    })
}