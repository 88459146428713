import React, {useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from 'components';
import { StoreProvider } from 'store/storeProvider';
import { ViewPage } from './cms/pages/viewPage/viewPage';
import { EditPage } from './cms/pages/editPage/editPage';
import { TopHeader } from './layout/topHeader/topHeader';
//import { Footer } from './layout/footer/footer';
import { BottomDock } from './layout/bottomDock/bottomDock';
// import { BlogView } from './site/blog/blogView';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { useParallax } from 'components';
import { AppVersionPage } from 'components';
import { MediaQueryStatus } from 'components';
import { AtdwPage, AtdwContext, ServicePage } from 'site/atdw/index';
import { ProvideAuth } from 'auth';
import { HelmetProvider } from 'react-helmet-async';
//import { MemberPageLayout } from 'site/member/index';
import { SiteAuthLayout } from 'site/siteAuth/index';
import { useStore } from "store/storeProvider";

function PageUpdated() {
  const { path } = useRouter();
  const parallax = useParallax();

  React.useLayoutEffect(() => {
    window.scrollTo(0, 0);
    parallax.reset();

    //document.getElementById("root").focus();

    // eslint-disable-next-line
  }, [path]);

  return null;
}

function AppLayout(props) {
  const { pathname } = useRouter();
  const { dispatch } = useStore();

  //const [scrolled, setScrolled] = useState(false)
  //const path = usePath();
  //const routeResult = useRoutes(routes);

  // const parallax = useParallax();
  // //console.log(path, routeResult);
  // const routeChanged = (path) => {
  //   //console.log(path);
  //   parallax.reset();
  // }

  useScrollPosition(({ prevPos, currPos }) => {
    //console.log(currPos.x)
    //console.log(currPos.y);
    if (currPos.y > 90) {
      document.querySelector('body').classList.add('scrolled')
    } else {
      document.querySelector('body').classList.remove('scrolled')
    }
    //setScrolled(currPos.y > 90)
  }, [], null, true)

  //const pageChanged = useCallback(path => routeChanged(path), [])
  //useLocationChange((path) => { routeChanged(path, history)})
  //useLocationChange(path => routeChanged(path))

  useEffect(() => {
    const googleMapScript = document.getElementById('googleMapScript');
    if(!googleMapScript){
      const _googleMapScript = document.createElement('script');
      _googleMapScript.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDdeE10fagsW-TTheuB3W-kGvkVTFe3NyY";
      _googleMapScript.id = "googleMapScript";
      _googleMapScript.async = true;
      _googleMapScript.defer = true;
      
      document.body.appendChild(_googleMapScript);
    }
  }, []);

  useEffect(() => {
    const _hash = window.location.hash;
    //console.log("pathname-", pathname, _hash);

    if(pathname === '/guide' && _hash === '#contact'){
      dispatch({type:'SET_STATE', payload : {enquiryType : "orderCaravanCampingGuide" }});
    }
    else{
      dispatch({type:'SET_STATE', payload : {enquiryType : "" }});
    }
    // eslint-disable-next-line
  }, [pathname]);

  if(pathname === '/ccia-suppliers'){
    return <Redirect to={{ pathname: "/caravan-and-camping-suppliers", state: { status: 301 } }} />;
  }

  return (
    <div className={`body-content ${pathname === '/' ? 'homepage' : 'subpage'}`}>
      <PageUpdated />
      <TopHeader></TopHeader>
      <div className="main-content">
        <div className="app-content">
          <Switch>
            <Route path="/ver" children={<AppVersionPage />} />
            <Route path="/cms/page/render/:id" children={<EditPage />} />
            <Route exact path="/admin/dashboard">
              <Redirect to="/members" />
            </Route>
            <Route path="/admin">
              <Redirect to="/admin" />
            </Route>
            <Route path="/atdwproduct/service/:slug" children = {<ServicePage />}/>
            <Route path="/atdwproduct/:slug" children = {<AtdwPage />}/>
            {/* <Route path="/memberpage" children = {<MemberPageLayout />}/> */}
            <Route path="/auth" children = {<SiteAuthLayout />}/>
            
            <Route path="/">
              <ViewPage />
            </Route>

          </Switch>
        </div>
        {/* <app-common-bottom-partial></app-common-bottom-partial> */}
      </div>
      {/* <Footer></Footer> */}
      <BottomDock></BottomDock>
      <MediaQueryStatus />
    </div>
  );
}

function App() {
  
  const [atdwDataHistory, setAtdwDataHistory] = useState(null);
  const [atdwDataHistory2, setAtdwDataHistory2] = useState(null);
  const [atdwTxaCheckInDate, setAtdwTxaCheckInDate] = useState(null);

  function setupAtdwDataHistory(value){
    console.log("setupAtdwDataHistory");
    setAtdwDataHistory(value);
  }

  function setupAtdwDataHistory2(value){
    console.log("setupAtdwDataHistory2");
    setAtdwDataHistory2(value);
  }

  function setupAtdwTxaCheckInDate(value){
    console.log("atdwTxaCheckInDate");
    setAtdwTxaCheckInDate(value);
  }
  
  return (
    
    <ProvideAuth>
    <StoreProvider>
    <HelmetProvider>
      <Router>
        <AtdwContext.Provider value={{atdwDataHistory, atdwDataHistory2, atdwTxaCheckInDate, setupAtdwDataHistory, setupAtdwDataHistory2, setupAtdwTxaCheckInDate}}>
          <AppLayout />
        </AtdwContext.Provider>
      </Router>
      </HelmetProvider>
    </StoreProvider>
    </ProvideAuth>
  );
}
export default App;