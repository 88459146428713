import { fb, validators } from "../../../../lib/form";

export function getSignupFormModel(data) {
  const model = fb.group({
    name: [
      data.name || "",
      [validators.Required()],
      { label: "Full Name", type: "text" },
    ],
    email: [
      data.email || "",
      [validators.Required(), validators.Email()],
      { label: "Email", type: "email" },
    ],
  });

  return model;
}
